import React, { Component } from 'react'

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length >= 2) return parts.pop().split(";").shift();


}



export default class ValidateAuthentication extends Component {

    render() {
        var role = getCookie('RoleType');
        if (role === 'Admin' || role === 'SpeechGuest' || role === 'TextGuest') {
        }
        else {
         window.location = 'https://login.learnisl.com/';
        }
        return (
            <div>
                {

                }
            </div>
        )
    }
}
