//import liraries
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper, Switch } from '@material-ui/core';
import { Card, CardContent, CardActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from './Avatar';
import API from './Constants/API';
import axios from 'axios';
import Speech from './Speech';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';


const useStyles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

class SpeechPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openCircleLoading: false,
            wordlist: [],
            statusMsg: 'Idle',
            cIndex: 0,
            maxIndex: 0,
            currentWord: "",
            islwords: ["idle"],
            lang: 'e',
            loading: false,
            langv: true,
        }
    }
    componentDidMount() {
        this.hiddenTextEvent();
        this.changeLanguage();

    }
    handleClose = () => {
        this.setState({
            openCircleLoading: false,
        });
    };
    handleToggle = () => {
        this.setState({
            openCircleLoading: !this.state.openCircleLoading,
        });
    };
    getISL = async (lang) => {
        this.setState({
            lang: lang,
            loading: true,

        });
        var transcript = document.getElementById('transcript');
        console.log(transcript.textContent);
        var data = new FormData();
        data.append('word', transcript.textContent.toUpperCase());
        data.append('lang', lang);

        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }

        await axios.post(API.Apiserveraddress + "GetSiGML.php", data, {
            headers: headers
        })
            .then(res => {

                this.setState({
                    data: res.data,
                    wordlist: res.data.data,
                    islwords: res.data.PoSTag,
                    loading: false,

                });

                this.get();
            })
    }

    get = () => {
        const data = this.state.data;

        var txt = document.getElementsByClassName('txtaSiGMLText');
        txt[0].innerHTML = data.SiGML;

        var btn = document.getElementsByClassName('bttnPlaySiGMLText');
        btn[0].click();

        this.setState({
            statusMsg: "Playing",

        });

    }

    nextSign = () => {
        const data = this.state.data;
        if (this.state.cIndex <= this.state.maxIndex) {
            const cur = data[this.state.cIndex];
            this.setState({
                currentWord: data[this.state.cIndex].word,
            })
            var btn = document.getElementsByClassName('bttnPlaySiGMLText');
            var txt = document.getElementsByClassName('txtaSiGMLText');
            if (data.length > 0) {
                txt[0].innerHTML = cur.SiGML;
            }
            btn[0].click();
        }
    }
    changeLanguage = () => {
        this.setState({
            langv: !this.state.langv
        });
        this.setLang();
    }
    setLang = () => {
        if (this.state.langv === false) {
            document.getElementById('engspk').style.display = "none";
            document.getElementById('edemobtn').style.display = "none";
            document.getElementById('hdemobtn').style.display = "block";
            // document.getElementById('eweb').style.visibility = "hidden";
            // document.getElementById('hweb').style.visibility = "visible";
            document.getElementById('hispk').style.display = "block";

        }
        else {
            document.getElementById('engspk').style.display = "block";
            document.getElementById('edemobtn').style.display = "block";
            document.getElementById('hdemobtn').style.display = "none";
            // document.getElementById('eweb').style.visibility = "visible";
            // document.getElementById('hweb').style.visibility = "hidden";
            document.getElementById('hispk').style.display = "none";

        }
    }

    setWords = (wrd, lang) => {
        var txt = document.getElementById('transcript');
        this.setState({
            lang: lang,
        })
        txt.innerHTML = wrd;
    }
    hiddenTextEvent = () => {
        var el = document.getElementById('gloss');
        const self = this;
        el.addEventListener("change", function () {
            self.setState({
                statusMsg: el.value,
            })
        });
    }
    playAgain = () => {
        var btn = document.getElementsByClassName('bttnPlaySiGMLText');
        btn[0].click();

    }
    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>
                <Grid item md={8} >
                    <Card >
                        <CardActions >
                            <div style={{ width: "100%", textAlign: "center", margin: 10, padding: 10 }}>

                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>English</Grid>
                                    <Grid item>
                                        <Switch size="small" onChange={() => { this.changeLanguage() }} />
                                    </Grid>
                                    <Grid item>Hindi</Grid>
                                </Grid>
                                <Speech />
                                <Backdrop className={classes.backdrop} open={this.state.openCircleLoading} onClick={this.handleClose}>
                                    <CircularProgress color="secondary" />
                                </Backdrop>
                            </div>
                        </CardActions>
                        <CardContent>
                            <Grid>
                                <Button id="edemobtn" variant="contained" onClick={() => { this.setWords("Actor was playing badminton in bedroom", 'e') }}>Eng Demo Text</Button>
                                <Button id="hdemobtn" variant="contained" onClick={() => { this.setWords('अभिनेता कमरे में खेल रहा था', 'h') }}>Hi Demo Text</Button>
                            </Grid>
                            <Grid >
                                {/* data: {JSON.stringify(this.state.wordlist)}<br/> */}
                                <ol>

                                    <>  {this.state.wordlist.map(row => (
                                        row.map((sub_row, index) => (

                                            <li key={index}
                                            >{sub_row['word'] + " - " + sub_row['text'] + " - " + sub_row['pos']} </li>))

                                    ))}
                                    </>
                                </ol>
                            </Grid>

                        </CardContent>
                        <CardContent>
                            <div>

                                <input id="gloss" type="hidden"></input>


                                <Button
                                    id="eweb"
                                    onClick={() => { this.getISL((this.state.langv) ? 'h' : 'e') }}
                                    variant="contained"
                                    color="primary">
                                    Translate
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={4}>
                    <Paper>

                        <Avatar />
                        <Grid>

                            <Alert severity="success" variant="outlined" icon={false}>
                                <AlertTitle><strong>Current :</strong>  {this.state.statusMsg}</AlertTitle>
                                <AlertTitle><strong>POS Tag :</strong>  {

                                    <>  {this.state.islwords.map((word, index) => (
                                        <Typography
                                            key={index}
                                            style={{ display: (word['text'] === this.state.statusMsg) ? "inline-block" : "none" }}
                                        >{word['pos']} </Typography>
                                    ))}
                                    </>

                                }</AlertTitle>
                                <>  {this.state.islwords.map((word, index) => (
                                    <Typography
                                        key={index}
                                        display="inline"
                                        color={(word['text'] === this.state.statusMsg) ? "secondary" : "primary"}
                                    >{word['text']} </Typography>
                                ))}
                                </>
                            </Alert>
                        </Grid>

                    </Paper>
                </Grid>
                <Backdrop className={classes.backdrop} open={this.state.loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        );
    }
}
export default withStyles(useStyles)(SpeechPlayer);