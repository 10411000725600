import { Avatar, Button, Card, CardContent, CardHeader, Container, Grid, IconButton, MuiThemeProvider, Typography, withStyles } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors';
import { Mic } from '@material-ui/icons';
import React, { Component } from 'react';
import AvatarPlayer from './Avatar';
import Recorder from 'react-mp3-recorder';
import DbConnection from './DbConnection';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Alert from '@material-ui/lab/Alert';
import { theme } from './Constants/CustomTheme'
import TextWordList from './TextWordList';

const db = new DbConnection();

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },

    avatar: {
        backgroundColor: green[500],
    },
});

class PhdSpeechText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: false,
            output1: '',
            // output2: '',
            // output3: '',
            lang: 'p',
            CardTitle: '',
            LogTitle: '',
            SpeedTitle: '',
            // data:'',
        };

    }
    componentDidMount() {
        // var lang = this.props.match.params?.word ? this.props.match.params.word : 'p';
        var lang = "p";
        this.setLanguage(lang);

    }
    _onRecordingComplete = (blob) => {
        console.log('recording', blob);
        this.speak(blob);
    }

    _onRecordingError = (err) => {
        console.log('recording error', err)
    }





    speak = async (blob) => {
        var result = await db.PhdCMURecog(blob);
        // this.setState({
        //     data:result['data']});
        if (result === '') {
            console('exit');
            return;
        }
        var output1 = '', output2 = '', output3 = '';
        if (this.state.lang == 'p') {
            output1 = result['word'];
            // output2 = result['data']['Hindi'];
            // output3 = result['data']['English'];
        }
        // else if (this.state.lang == 'e') {
        //     output1 = result['data']['English'];
        //     output2 = result['data']['Hindi'];
        //     output3 = result['data']['Punjabi'];
        // }
        // else {
        //     output1 = result['data']['Hindi'];
        //     output2 = result['data']['Punjabi'];
        //     output3 = result['data']['English'];
        // }

        this.setState({
            output1: output1,
            // output2: output2,
            // output3: output3,
            // data: result,
        });
        //     this.get();
    }

    // get = () => {
    //     const data = this.state.data;

    //     var txt = document.getElementsByClassName('txtaSiGMLText');
    //     txt[0].innerHTML = data.SiGML;

    //     var btn = document.getElementsByClassName('bttnPlaySiGMLText');
    //     btn[0].click();

    //     this.setState({
    //         statusMsg: "Playing",
    //     });

    // }
    setLanguage = (lang) => {
        this.setState({
            lang: lang,
        });
        if (lang == 'e') {
            this.setState({
                CardTitle: 'Press and Hold the Mic button to record',
                LogTitle: 'Current',
                SpeedTitle: 'Speed',
            });
        }
        else if (lang == 'p') {
            this.setState({
                CardTitle: 'ਰਿਕਾਰਡ ਕਰਨ ਲਈ ਮਾਈਕ ਬਟਨ ਨੂੰ ਦਬਾ ਕੇ ਰੱਖੋ',
                LogTitle: 'ਹੁਣ',
                SpeedTitle: 'ਗਤੀ',
            });
        }
        else {
            this.setState({
                CardTitle: 'रिकॉर्ड करने के लिए माइक बटन को दबाकर रखें',
                LogTitle: 'वर्तमान',
                SpeedTitle: 'गति',
            });
        }


    }


    render() {
        const { classes } = this.props;

        return (
            <MuiThemeProvider theme={theme}>
                <Grid container spacing={2} >
                    <Grid item sm={7}>
                        
                        <Card style={{marginTop:20}}>
                        <CardHeader
                                title="Select Word to Play ISL Gesture Animation"
                                >
                            </CardHeader>
                            <CardContent style={{ textAlign: 'center' }}>

                                <TextWordList />
                            </CardContent>

                        </Card>
                        <Card>
                            <CardHeader
                                title={this.state.CardTitle}
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        R
                                    </Avatar>
                                }>
                            </CardHeader>
                            <CardContent style={{ textAlign: 'center' }}>
                                <Grid container spacing={3}>
                                    <Typography>{this.state.output}</Typography>
                                </Grid>
                                <Grid container spacing={3} style={{ margin: 10 }}>

                                    {/* <Grid item sm={5} style={{ textAlign: 'right' }}>
                                    <Button variant={(this.state.lang == 'p') ? "contained" : "outlined"} color="secondary" onClick={() => { this.setLanguage('p') }}>ਪੰਜਾਬੀ</Button>

                                </Grid> */}


                                    {/* <Grid item sm={2} >

                                    <Button variant={(this.state.lang == 'h') ? "contained" : "outlined"} color="secondary" onClick={() => { this.setLanguage('h') }}>हिंदी</Button>
                                </Grid>*/}
                                    <Grid item sm={5} >

                                        <Recorder
                                            onRecordingComplete={this._onRecordingComplete}
                                            onRecordingError={this._onRecordingError}
                                        />
                                    </Grid>
                                    <Grid style={{ alignSelf:'center'}}>
                                        <Alert severity="success" variant="outlined" icon={false} style={{minWidth:"200px"}}>
                                            <AlertTitle>
                                                <strong>{this.state.output1}</strong>
                                            </AlertTitle>
                                        </Alert>
                                    </Grid>

                                </Grid>




                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item sm={5}>
                        <Card>
                            <CardContent>
                                <AvatarPlayer />

                            </CardContent>

                        </Card>
                    </Grid>

                </Grid>
            </MuiThemeProvider>
        )
    }
}
export default withStyles(useStyles)(PhdSpeechText);
