//import API from '../../constants/API';

import axios from 'axios';
import API from './Constants/API';

export default class DbConnection {
    dbconenction() {
        console.log('dbconnection');
    }
    
    async parseString(str) {
        var result;
        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
        await axios.get("", {
            headers: headers,
            onUploadProgress: progressEvent => {
                let uploaded = Math.round(progressEvent.loaded / progressEvent.total * 100);
                //progress(uploaded);
            }
        }).then((res) => {
            // console.log(res);
            result = res;

        }).catch(function (Error) {
            alert("Could not connect to the server ");
        });

        return result;
    }

    async EngHinSiGML(lang, word) {
        
        var result = 0;

        const data = new FormData();
        
        data.append("lang", lang);
        data.append("word", word);

        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }

     

        await axios.post('https://speech.learnisl.com/api/GetEngHinWordSiGML.php', data, {crossDomain: true,
            headers: headers
        }).then((res) => {
            // console.log(res);
            result = res.data;

        }).catch(function (Error) {
            alert("Could not connect to the server ");
        });

        return result;
    }
     async CMUData(lang, audio) {
        
        var result = 0;
        
        //console.log(SbjID);

        const data = new FormData();
        //data.append("Title", Title);
        //data.append("SbjID", SbjID);
        data.append("lang", lang);
        data.append("file", audio);

        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }

     

        await axios.post('https://speech.learnisl.com/api/GetCMUData.php', data, {crossDomain: true,
            headers: headers
        }).then((res) => {
            // console.log(res);
            result = res.data;

        }).catch(function (Error) {
            alert("Could not connect to the server ");
        });

        return result;
    }

    async PhdCMURecog (audio) {
        
        var result = 0;
        
        //console.log(SbjID);

        const data = new FormData();
        //data.append("Title", Title);
        //data.append("SbjID", SbjID);
        //data.append("lang", lang);
        data.append("dataFile", audio);

        const headers = {
          //  'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }

     

        await axios.post('https://sphinx.learnisl.com/pbispeech', data, {crossDomain: true,
            headers: headers
        }).then((res) => {
             console.log(res);
            result = res.data;

        }).catch(function (Error) {
            alert("Could not connect to the server ");
        });

        return result;
    }
    async ContributeSpeech(lang, audio, pname, age, sex, sentence) {
        
        var result = 0;
        

        const data = new FormData();
        data.append("lang", lang);
        data.append("file", audio);
        data.append("pname", pname);
        data.append("age", age);
        data.append("sex", sex);
        data.append("sentence", sentence);

        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }

     

        await axios.post('https://speech.learnisl.com/api/ContributeSpeech.php', data, {crossDomain: true,
            headers: headers
        }).then((res) => {
            result = res.data;
        }).catch(function (Error) {
            alert("Could not connect to the server ");
        });

        return result;
    }
    async GetTextSign(lang, word) {
        
        var result = 0;

        const data = new FormData();
        
        data.append("lang", lang);
        data.append("word", word);

        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }

     

        await axios.post('https://speech.learnisl.com/api/GetTextSign.php', data, {crossDomain: true,
            headers: headers
        }).then((res) => {
            // console.log(res);
            result = res.data;

        }).catch(function (Error) {
            alert("Could not connect to the server ");
        });

        return result;
    }

    async GetTextSentenceSign(lang, sentence){
        var result = "";
        var data = new FormData();
        data.append('word', sentence.toUpperCase());
        data.append('lang', lang);

        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }

        await axios.post("https://speech.learnisl.com/api/GetSiGML.php", data, {
            headers: headers
        })
            .then(res => {
                result = res.data;
            });
            return result; 
    }

    async GetRandomWords(params){
        var data = new FormData();
        data.append('lang', params);

        var result = 0;

        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }

        await axios.post(API.Apiserveraddress + "RandomWords.php", data, {
            headers: headers
        }).then((res) => {
            // console.log(res);
            result = res.data;

        }).catch(function (Error) {
            alert("Could not connect to the server ");
        });

        return result;
    }

    async GetSignByWID(params){
        var data = new FormData();
        data.append('wid', params);

        var result = 0;

        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }

        await axios.post(API.Apiserveraddress + "GetSignByWID.php", data, {
            headers: headers
        }).then((res) => {
            // console.log(res);
            result = res.data;

        }).catch(function (Error) {
            alert("Could not connect to the server ");
        });

        return result;
    }
    async GetWordList(params){
        var data = new FormData();
        data.append('lang', params);

        var result = 0;

        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }

        await axios.post(API.Apiserveraddress + "GetWordList.php", data, {
            headers: headers
        }).then((res) => {
            // console.log(res);
            result = res.data;

        }).catch(function (Error) {
            alert("Could not connect to the server ");
        });

        return result;
    }
    async SearchWord(lang,params){
        var data = new FormData();
        data.append('lang', lang);
        data.append('word', params);

        var result = 0;

        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }

        await axios.post(API.Apiserveraddress + "GetWordLike.php", data, {
            headers: headers
        }).then((res) => {
            // console.log(res);
            result = res.data;

        }).catch(function (Error) {
            alert("Could not connect to the server ");
        });

        return result;
    }
    async GetWordsByTag(params,lang){
        var data = new FormData();
        data.append('tag', params);
        data.append('lang', lang);

        var result = 0;

        const headers = {
            'Accept': 'application/json, text/plain, */*',
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }

        await axios.post(API.Apiserveraddress + "GetWordsByTag.php", data, {
            headers: headers
        }).then((res) => {
            // console.log(res);
            result = res.data;

        }).catch(function (Error) {
            alert("Could not connect to the server ");
        });

        return result;
    }
}