module.exports = {
    "data":
    [
        {
            "EWord": "Academics",
            "PWord": "ਵਿੱਦਿਅਕ",
            "HWord": "शैक्षणिक "
        },
        {
            "EWord": "Actions",
            "PWord": "ਕਾਰਵਾਈਆਂ",
            "HWord": "कार्रवाई "
        },
        {
            "EWord": "Animals",
            "PWord": "ਜਾਨਵਰ",
            "HWord": "जानवर "
        },
        {
            "EWord": "Behavioural",
            "PWord": "ਵਿਹਾਰਕ ",
            "HWord": "व्यवहार "
        },
        {
            "EWord": "Body-Parts",
            "PWord": "ਸਰੀਰਕ ਅੰਗ",
            "HWord": "शरीर के अंग "
        },
        {
            "EWord": "Colors",
            "PWord": "ਰੰਗ",
            "HWord": "रंग  "
        },
        {
            "EWord": "Counting",
            "PWord": "ਗਿਣਤੀ",
            "HWord": "गिनती "
        },
        {
            "EWord": "Date-Time",
            "PWord": "ਮਿਤੀ-ਸਮਾਂ",
            "HWord": "दिनांक-समय "
        },
        {
            "EWord": "Days",
            "PWord": "ਦਿਨ",
            "HWord": "दिन "
        },
        {
            "EWord": "Directions",
            "PWord": "ਦਿਸ਼ਾਵਾਂ",
            "HWord": "दिशा-निर्देश "
        },
        {
            "EWord": "Eatable",
            "PWord": "ਖਾਣਯੋਗ",
            "HWord": "खाने योग्य "
        },
        {
            "EWord": "English-Alphabets",
            "PWord": "ਅੰਗਰੇਜ਼ੀ-ਅੱਖਰ",
            "HWord": "अंग्रेजी-अक्षर "
        },
        {
            "EWord": "Enjoyable",
            "PWord": "ਆਨੰਦਦਾਇਕ",
            "HWord": "सुखद "
        },
        {
            "EWord": "Family-Relation",
            "PWord": "ਪਰਵਾਰ-ਸੰਬੰਧੀ",
            "HWord": "परिवार-संबंध "
        },
        {
            "EWord": "Features",
            "PWord": "ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ",
            "HWord": "विशेषताएं "
        },
        {
            "EWord": "Fruits",
            "PWord": "ਫਲ",
            "HWord": "फल "
        },
        {
            "EWord": "Languages",
            "PWord": "ਭਾਸ਼ਾਵਾਂ",
            "HWord": "बोली "
        },
        {
            "EWord": "Locations",
            "PWord": "ਥਾਵਾਂ ",
            "HWord": "स्थान "
        },
        {
            "EWord": "Mathematics",
            "PWord": "ਗਣਿਤ",
            "HWord": "गणित"
        },
         {
            "EWord": "Medical",
            "PWord": "ਮੈਡੀਕਲ",
            "HWord": "मेडिकल "
        },
        {
            "EWord": "Miscllaneous",
            "PWord": "ਫੁਟਕਲ",
            "HWord": "विविध "
        },
        {
            "EWord": "Months",
            "PWord": "ਮਹੀਨੇ",
            "HWord": "महीने "
        },
        {
            "EWord": "Months-Indian",
            "PWord": "ਮਹੀਨੇ-ਭਾਰਤੀ",
            "HWord": "महीने-भारतीय "
        },
        {
            "EWord": "Objects",
            "PWord": "ਵਸਤੂਆਂ",
            "HWord": "वस्तुऐं  "
        },
        {
            "EWord": "Planets",
            "PWord": "ਗ੍ਰਹਿ",
            "HWord": "ग्रह "
        },
        {
            "EWord": "Positive",
            "PWord": "ਸਕਾਰਾਤਮਕ",
            "HWord": "सकारात्मक "
        },
        {
            "EWord": "Professions",
            "PWord": "ਪੇਸ਼ੇ",
            "HWord": "व्यवसाय "
        },
        {
            "EWord": "Punjabi-Alphabets",
            "PWord": "ਪੰਜਾਬੀ-ਅੱਖਰ",
            "HWord": "पंजाबी-अक्षर "
        },
        {
            "EWord": "Quantity",
            "PWord": "ਮਾਤਰਾ",
            "HWord": "मात्रा "
        },
        {
            "EWord": "Questions",
            "PWord": "ਸਵਾਲ",
            "HWord": "प्रशन "
        },
        {
            "EWord": "Rivers",
            "PWord": "ਨਦੀਆਂ",
            "HWord": "नदियाँ "
        },
        {
            "EWord": "Seasons",
            "PWord": "ਰੁੱਤਾਂ",
            "HWord": "मौसम  "
        },
        {
            "EWord": "Sports",
            "PWord": "ਖੇਡਾਂ",
            "HWord": "खेल "
        },
        {
            "EWord": "Study",
            "PWord": "ਅਧਿਐਨ",
            "HWord": "अध्ययन "
        },
        {
            "EWord": "Subjects",
            "PWord": "ਵਿਸ਼ੇ",
            "HWord": " विषय "
        },
        {
            "EWord": "Vegetables",
            "PWord": "ਸਬਜ਼ੀਆਂ",
            "HWord": "सब्जियाँ  "
        },
        {
            "EWord": "Wearable",
            "PWord": "ਪਹਿਨਣਯੋਗ",
            "HWord": "पहनने योग्य"
        }
    ]   
}