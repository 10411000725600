import React from 'react';
import ReactDOM from 'react-dom';

import { Route, BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';
// import SignIn from './MyApp/SignIn';
import Index from './MyApp/Index';
import CMUSphinx from './MyApp/CMUSPhinx';
import TextWordSign from './MyApp/TextWordSign';
import TextSentenceSign from './MyApp/TextSentenceSign';
import Search from './MyApp/Search';
import Contribute from './MyApp/Contribute';
import PhdSpeechText from './MyApp/PhdSpeechText';

const routing = (
  <Router>
    <div>
      <Route exact path="/:lang?" component={Index} /> 
      <Route exact path="/Text" component={TextWordSign} />
      <Route exact path="/TextSentence/:lang" component={TextSentenceSign} />
      <Route exact path="/CMUSphinx/:lang" component={CMUSphinx} />
      <Route exact path="/PhdSpeechText/:lang" component={PhdSpeechText} />
      {/* <Route exact path="/" component={PhdSpeechText} /> */}
      <Route exact path="/Contribute/:lang" component={Contribute} />
      <Route path="/search/:lang/:word?" render={(props) => <Search {...props} />} />

      {/* <Route exact path="/aboutus" component={AboutUs} />
      <Route exact path="/videolectures/:sbjid/:subjectname/" component={VideoLectures} />
    */}



    </div>
  </Router>
)

ReactDOM.render(
  routing,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

