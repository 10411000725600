import { createMuiTheme } from "@material-ui/core";
import { blue, green, yellow } from "@material-ui/core/colors";

export const theme = createMuiTheme (
    {
        palette: {
          primary: {
              main:'#fdc800',
              light: '#fdc800',
              dark: '#002147',
              contrastText: "#002147",
              
            },
          secondary: {
              main:'#002147',
              dark:'#fdc800',
          },
        },

        overrides: {
            MuiButton: {
              contained:{
                  borderRadius:0,
                  boxShadow:null,

                  '&:hover':{
                  boxShadow:null,
                 
                  },
              },
              containedPrimary:{
                '&:hover':{
                    color:'#fff',
                    },
              },
              containedSecondary:{
                '&:hover':{
                    color:'#002147',
                    },
              },
           

            outlined:{
                borderRadius:0,
                boxShadow:null,

                '&:hover':{
                boxShadow:null,
               
                },
            },
            outlinedPrimary:{
              '&:hover':{
                  backgroundColor:'#fdc800',
                  color:'#002147',
                  },
            },
            outlinedSecondary:{
              '&:hover':{
                  backgroundColor:'#002147',
                  color:'#fff',
                  },
            },
          },
        //   MuiChip:{
        //     colorPrimary:{
        //         '&:hover':{
        //             color:'#fff',
        //             },
        //       },
        //       colorSecondary:{
        //         '&:hover':{
        //             background:'#fff',
        //             color:'#002147',
        //             },
        //       },
        //   }
            
        },
      }
)

