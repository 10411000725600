//import liraries
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import ValidateAuthentication from './ValidateAuthentication';

import { Grid, Container } from '@material-ui/core';

import SpeechPlayer from './SpeechPlayer';


import { MuiThemeProvider } from '@material-ui/core/styles';

import {theme} from './Constants/CustomTheme';

// const theme = createMuiTheme(
//     {
//         palette: {
//           primary: yellow,
//           secondary: green,
//         },
//         overrides: {
//             MuiButton: {
              
//                 borderRadius: 0,
//                 margin: 5,
//                 '&:hover': {
//                     background: '#fdc800',
//                     color: '#002147',
//                     borderColor: '#fdc800',
//                 }
//             }
//         },
//       }
// )

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    paddingTop: 5,
  },
  paperBlock: {
    padding: 10,
  },
  // backdrop: {
  //   zIndex: theme.zIndex.drawer + 1,
  //   color: '#fff',
  // },
});
// create a component
class Index extends Component {
  
  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
              <ValidateAuthentication />

      <Grid>
        {/* <Grid>

          <Navbar />
        </Grid> */}
        <Container className={classes.container}>
          <SpeechPlayer />
        </Container>
      </Grid>
      </MuiThemeProvider>
    );
  }
}
export default withStyles(useStyles)(Index);
