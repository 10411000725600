import { Avatar, Backdrop, Box, Button, Card, CardContent, CardHeader, CircularProgress, colors, Container, Dialog, DialogContent, DialogTitle, Fade, FormControl, FormControlLabel, FormLabel, Grid, Modal, MuiThemeProvider, Paper, Radio, RadioGroup, TextField, Typography, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import React, { Component } from 'react'
import Recorder from 'react-mp3-recorder';
import { theme } from './Constants/CustomTheme'

import DbConnection from './DbConnection';

const DataFile = require('./Constants/data');
const db = new DbConnection();
const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },

    avatar: {
        backgroundColor: '#002147',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});
class Contribute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Lang: 'e',
            Output: '',
            CardTitle: '',
            LogTitle: '',
            SpeedTitle: '',
            CurrentSentence: '',
            Name: '',
            Age: 18,
            Sex: 'f',
            OpenDialog: false,
            file: null,
            SubmitButton:false,
            Loading: false,
            AudioUrl:'',
        }
    }
    componentDidMount() {

        this.setLanguage(this.props.match.params.lang);
        this.load(this.props.match.params.lang);

    }
    load = (lang) => {

        var row = this.getRow();
        var sentence = "";

        if (lang === 'h') {
            sentence = row.HWord;
        }
        else if (lang === 'p') {
            sentence = row.PWord;
        }
        else {
            sentence = row.EWord;
        }

        this.setState({
            CurrentSentence: sentence,
        });
    }
    _onRecordingComplete = (blob) => {
        //console.log('recording', window.URL.createObjectURL(blob));

       // this.speak(blob);
        this.setState({
            file: blob,
            SubmitButton:true,
            AudioUrl:window.URL.createObjectURL(blob)
        });



    }

    _onRecordingError = (err) => {
        console.log('recording error', err)
    }

    handleCloseDialog = () => {
        this.setState({
            OpenDialog: false,
        })
    }
    handleOpenDialog = () => {
        this.setState({
            OpenDialog: true,
        });
    }

    setLanguage = (lang) => {

        this.setState({
            Lang: lang,
        });

        if (lang === 'h') {

            this.setState({
                CardTitle: 'रिकॉर्ड करने के लिए माइक बटन को दबाकर रखें',
                LogTitle: 'वर्तमान',
                SpeedTitle: 'गति',
            });
        }

        else if (lang === 'p') {
            this.setState({
                CardTitle: 'ਰਿਕਾਰਡ ਕਰਨ ਲਈ ਮਾਈਕ ਬਟਨ ਨੂੰ ਦਬਾ ਕੇ ਰੱਖੋ',
                LogTitle: 'ਹੁਣ',
                SpeedTitle: 'ਗਤੀ',
            });
        }
        else {
            this.setState({
                CardTitle: 'Press and Hold the Mic button to record',
                LogTitle: 'Current',
                SpeedTitle: 'Speed',
            });
        }


    }

    submitForm = () => {
        console.log(this.state.file);

        this.speak(this.state.file);
        this.setState({
            OpenDialog: false,
        })
    }

    speak = async (blob) => {
        if (this.state.Name.trim().length === 0) {

            this.setState({
                OpenDialog: true,
            });
            return;
        }
        this.setState({
            Loading: true,
        });
        var result = await db.ContributeSpeech(this.state.Lang, blob, this.state.Name, this.state.Age, this.state.Sex, this.state.CurrentSentence);
        // this.setState({
        //     data:result['data']});
        if (result === '') {
            console('exit');
            return;
        }

        this.setState({
            Loading: false,
            SubmitButton:false,
        });
        alert(result);

        this.load(this.state.Lang);
    }
    getRow = () => {
        var index = Math.floor(Math.random() * DataFile.data.length);
        //  console.log( index);
        var ob = DataFile.data[index];
        return ob;
    }

    render() {
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={theme}>

                <Container>

                    <Grid >
                        <Card>
                            <CardHeader
                                title={this.state.CardTitle}
                                avatar={
                                    <Avatar aria-label="recipe" variant="square" className={classes.avatar}>
                                        R
                            </Avatar>
                                }>
                            </CardHeader>
                            <CardContent style={{ textAlign: 'center' }}>
                               
                               <Box padding={5}>
                                        <Typography variant="h4" align="center" >
                                            {this.state.CurrentSentence}
                                        </Typography>
                               </Box>

                                    {/* <Grid item sm={5} style={{ textAlign: 'right' }}>
                                    <Button variant={(this.state.lang == 'p') ? "contained" : "outlined"} onClick={() => { this.setLanguage('p') }}>ਪੰਜਾਬੀ</Button>

                                </Grid>
                                <Grid item sm={2} >

                                    <Button variant={(this.state.lang == 'h') ? "contained" : "outlined"} color="secondary" onClick={() => { this.setLanguage('h') }}>हिंदी</Button>
                                </Grid>
                                <Grid item sm={5} style={{ textAlign: 'left' }}>
                                    <Button variant={(this.state.lang == 'e') ? "contained" : "outlined"} color="primary" onClick={() => { this.setLanguage('e') }}>English</Button>
                                </Grid> */}

                                <Recorder
                                
                                
                                    onRecordingComplete={this._onRecordingComplete}
                                    onRecordingError={this._onRecordingError}
                                />
                                <Box padding={5}>
                                    
                                    <audio id="mySound" controls preload="none" name="media" src={this.state.AudioUrl} ></audio>
                                </Box>
                                <Box padding={5}>
                                    <Button color="primary" variant="contained" onClick={() => { this.speak(this.state.file) }} disabled={!this.state.SubmitButton}>Submit</Button>
                                </Box>
                            </CardContent>
                        </Card>



                    </Grid>
                </Container>
                <Backdrop className={classes.backdrop} open={this.state.Loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Dialog onClose={this.state.handleCloseDialog} aria-labelledby="info-dialog-title" open={this.state.OpenDialog}>
                    <DialogTitle id="info-dialog-title">Please enter your basic info</DialogTitle>
                    <DialogContent>

                        <Grid container spacing={2} direction="column" >

                            <Grid item >
                                <TextField placeholder="Your Name" variant="outlined" color="primary" fullWidth value={this.state.Name} onChange={(e) => { this.setState({ Name: e.target.value }) }} />
                            </Grid>
                            <Grid item>
                                <TextField placeholder="Your age" type="number" variant="outlined" color="primary" fullWidth value={this.state.Age} onChange={(e) => { this.setState({ Age: e.target.value }) }} />
                            </Grid>

                            <Grid item>
                                <Box textAlign="center">

                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="gender" row name="gender1" value={this.state.Sex} onChange={(e) => { this.setState({ Sex: e.target.value }) }}>
                                            <FormControlLabel value="f" control={<Radio />} label="Female" />
                                            <FormControlLabel value="m" control={<Radio />} label="Male" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item >
                                <Box textAlign="center">

                                    <Button color="primary" variant="contained" onClick={() => { this.submitForm() }}>Submit</Button>
                                </Box>

                            </Grid>

                        </Grid>
                    </DialogContent>


                </Dialog>


            </MuiThemeProvider>
        )
    }
}

export default withStyles(useStyles)(Contribute);
