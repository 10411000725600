import { AppBar, Button, Toolbar, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },

    title: {
        flexGrow: 1,
    }
});
function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length >= 2) return parts.pop().split(";").shift();
  }
  
class Navbar extends Component {
   
    constructor(props) {
        super(props);
        if(getCookie('RoleType')!='SpeechGuest'){
       //     window.location='https://login.learnisl.com/';
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <AppBar position="static">
                <Toolbar>


                    <Button variant="outlined" onClick={() => this.props.history.push('/')}>
                        Speech (E/H)
                    </Button>
                    <Button variant="outlined" onClick={() => this.props.history.push('/TextSentence')}>
                        Sentence (Text)
                    </Button>
                    <Button variant="outlined" onClick={() => this.props.history.push('/Text')}>
                        Punjabi (Text)
                    </Button>
                    <Button variant="outlined" onClick={() => this.props.history.push('/CMUSphinx')}>
                        Punjabi (Speech)
                    </Button>
                </Toolbar>
            </AppBar>
        )
    }
}
export default withStyles(useStyles)(withRouter(Navbar));
