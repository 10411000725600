import { Avatar, Box, Button, Card, CardContent, CardHeader, Container, FormControl, Grid, IconButton, InputLabel, MenuItem, MuiThemeProvider, Select, Typography, withStyles } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors';
import React, { Component } from 'react';
import AvatarPlayer from './Avatar';
import Recorder from 'react-mp3-recorder';
import DbConnection from './DbConnection';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Alert from '@material-ui/lab/Alert';
import { theme } from './Constants/CustomTheme'
import { data } from './Constants/category';
import Speech from './Speech';
var category = require('./Constants/category');

const db = new DbConnection();

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },

    avatar: {
        backgroundColor: green[500],
    },
});
class CMUSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: false,
            output1: '',
            output2: '',
            output3: '',
            lang: 'p',
            CardTitle: '',
            LogTitle: '',
            SpeedTitle: '',
            data: '',
            langcol: 'engw',
            wdblist: [],
            menu1: 'All',
            menulist1: ['All', 'School', 'Family-Relation', 'Animals', 'Birds', 'Colors', 'Months'],

        };

    }
    componentDidMount() {
        // var lang = this.props.match.params?.word ? this.props.match.params.word : 'p';
        var lang = this.props.match.params.lang;
        this.setState({
            lang:lang,
            menulist1:category.data,

        });
        this.child = React.createRef();

        this.setLanguage(lang);
        

    }
    _onRecordingComplete = (blob) => {
        console.log('recording', blob);
        this.speak(blob);
    }

    _onRecordingError = (err) => {
        console.log('recording error', err)
    }





    speak = async (blob) => {
        var result = await db.CMUData(this.state.lang, blob);
        // this.setState({
        //     data:result['data']});
        console.log(result)
        if (result === 0) {
            console.log('exit');
            output1 = "-";
            output2 = "-";
            output3 = "-";
            return;
        }
        var output1 = '', output2 = '', output3 = '';
        if (this.state.lang == 'e') {
            output1 = result['data']['English'];
            output2 = result['data']['Hindi'];
            output3 = result['data']['Punjabi'];
        }
        else if (this.state.lang == 'p') {
            output1 = result['data']['Punjabi'];
            output2 = result['data']['Hindi'];
            output3 = result['data']['English'];
        }
        else {
            output1 = result['data']['Hindi'];
            output2 = result['data']['Punjabi'];
            output3 = result['data']['English'];
        }

        this.setState({
            output1: output1,
            output2: output2,
            output3: output3,
            data: result,
        });
        this.get();
    }

    get = () => {
        const data = this.state.data;

        var txt = document.getElementsByClassName('txtaSiGMLText');
        txt[0].innerHTML = data.SiGML;

        var btn = document.getElementsByClassName('bttnPlaySiGMLText');
        btn[0].click();

        this.setState({
            statusMsg: "Playing",
        });

    }
    getEnglishHindi = async() => {
        var txt = document.getElementById('transcript').innerHTML;
        txt = txt.replace('।', '');
        txt = txt.replace('.', '');
        txt = txt.replace('?', '');

console.log(txt);
        var result = await db.EngHinSiGML(this.state.lang, txt);
        // this.setState({
        //     data:result['data']});
        console.log(result)
        if (result === 0) {
            console.log('exit');
            output1 = "-";
            output2 = "-";
            output3 = "-";
            return;
        }
        var output1 = '', output2 = '', output3 = '';
        if (this.state.lang == 'e') {
            output1 = result['data']['English'];
            output2 = result['data']['Hindi'];
            output3 = result['data']['Punjabi'];
        }
        else if (this.state.lang == 'p') {
            output1 = result['data']['Punjabi'];
            output2 = result['data']['Hindi'];
            output3 = result['data']['English'];
        }
        else {
            output1 = result['data']['Hindi'];
            output2 = result['data']['Punjabi'];
            output3 = result['data']['English'];
        }

        this.setState({
            output1: output1,
            output2: output2,
            output3: output3,
            data: result,
        });
        this.get();
    }
    setLanguage = async (lang) => {
        this.setState({
            lang: lang,
        });
        if (lang == 'e') {
            this.setState({
                CardTitle: 'Click the Mic button to record',
                LogTitle: 'Current',
                langcol: 'engw',
                SpeedTitle: 'Speed',
            });
                
        }
        else if (lang == 'p') {
            this.setState({
                CardTitle: 'ਰਿਕਾਰਡ ਕਰਨ ਲਈ ਮਾਈਕ ਬਟਨ ਨੂੰ ਦਬਾ ਕੇ ਰੱਖੋ',
                LogTitle: 'ਹੁਣ',
                langcol: 'pbw',
                SpeedTitle: 'ਗਤੀ',
            });

            //document.getElementById('engspk').style.display = "none";
            //document.getElementById('hispk').style.display = "none";
        }
        else {
            this.setState({
                CardTitle: 'रिकॉर्ड करने के लिए माइक बटन को दबाकर रखें',
                LogTitle: 'वर्तमान',
                langcol: 'hiw',
                SpeedTitle: 'गति',
            });
               }

        var result2 = await db.GetWordList(lang);
      //  console.log("data"+result2);

        if (result2 === 0) {
            console.log('exit');
            return;
        }
        else {
            this.setState({
                wdblist: result2,
            });
        }
        console.log( this.child);

    }
    changeCategory = async (e) => {
        var val = e.target.value;
        this.setState({
            menu1: val
        });
        if (val == 'All') {
            var result2 = await db.GetWordList(this.state.lang);

            if (result2 === 0) {
                console.log('exit');
                return;
            }
            else {
                this.setState({
                    wdblist: result2,
                });
            }
        }
        else {

            var result2 = await db.GetWordsByTag(val, this.state.lang);
            if (result2 === '') {
                console.log('exit');
                return;
            }
            else {
                this.setState({
                    wdblist: result2,
                });
            }
        }


    }

    render() {
        const { classes } = this.props;

        return (
            <MuiThemeProvider theme={theme}>
                <Grid container spacing={2}>
                    <Grid item md={7}>
                        <Card>
                            <CardHeader
                                title={this.state.CardTitle}
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        R
                                    </Avatar>
                                }>
                            </CardHeader>
                            <CardContent style={{ textAlign: 'center' }}>
                                <Grid container spacing={3}>
                                    <Typography>{this.state.output}</Typography>
                                </Grid>
                                <Grid container spacing={3} style={{ margin: 10 }}>

                                    <Grid item sm={5} style={{ textAlign: 'right' }}>
                                        <Button variant={(this.state.lang == 'p') ? "contained" : "outlined"} color="secondary" onClick={() => { this.setLanguage('p') }}>ਪੰਜਾਬੀ</Button>

                                    </Grid>
                                    <Grid item sm={2} >

                                        <Button variant={(this.state.lang == 'h') ? "contained" : "outlined"} color="secondary" onClick={() => { this.setLanguage('h') }}>हिंदी</Button>
                                    </Grid>
                                    <Grid item sm={5} style={{ textAlign: 'left' }}>
                                        <Button variant={(this.state.lang == 'e') ? "contained" : "outlined"} color="secondary" onClick={() => { this.setLanguage('e') }}>English</Button>
                                    </Grid>

                                </Grid>
                                {(this.state.lang == 'p')?
                                <Recorder
                                    onRecordingComplete={this._onRecordingComplete}
                                    onRecordingError={this._onRecordingError}
                                />:
                                <Speech lang={this.state.lang} />
                                }
                                <div>
                                {(this.state.lang == 'p')?<></>:
                                        <Button variant={"contained"} color="secondary" onClick={() => { this.getEnglishHindi() }}>Translate</Button>
                            }
                                </div>
                            </CardContent>

                        </Card>
                        <Card>
                            <CardContent>
                                <Box padding={2}>
                                    <Typography variant="body1" display='inline'>Select Category: </Typography>
                                    <FormControl className={classes.formControl} variant='outlined' >
                                        <Select
                                            labelId="menulabel1"
                                            placeholder='Select'
                                            id="menu1"
                                            value={this.state.menu1}
                                            onChange={(e) => this.changeCategory(e)}
                                        >
                                            {this.state.menulist1.map(row => (
                                                <MenuItem value={row.EWord}>{
                                                    (this.state.lang==='e')?row.EWord:(this.state.lang==='p')?row.PWord:row.HWord
                                                }</MenuItem>))}

                                        </Select>
                                    </FormControl>
                                </Box>
                            </CardContent>
                            <CardContent>
                                <>  {this.state.wdblist.map((row, index) => (
                                    <Button className={classes.btn} key={index} variant="outlined">{row[this.state.langcol]}</Button>
                                ))}
                                </>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={5}>
                        <Card>
                            <CardContent>
                                <AvatarPlayer />
                                <Alert severity="success" variant="outlined" icon={false}>
                                    <AlertTitle><strong>{this.state.LogTitle} :</strong>  {this.state.output1} / {this.state.output2} / {this.state.output3}</AlertTitle>
                                </Alert>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </MuiThemeProvider>
        )
    }
}
export default withStyles(useStyles)(CMUSection);
