import { Container, createMuiTheme, Grid, MuiThemeProvider, Toolbar, Typography, withStyles } from '@material-ui/core'
import { deepOrange, teal } from '@material-ui/core/colors';
import React, { Component } from 'react'
import CMUSection from './CMUSection';
import Navbar from './Navbar';
import ValidateAuthentication from './ValidateAuthentication';
const theme = createMuiTheme(
    {
        palette: {
          primary: teal,
          secondary: deepOrange,
        },
      }
)

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    paddingTop: 5,
  },
  paperBlock: {
    padding: 10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});
class CMUSPhinx extends Component {
    render() {
    const { classes } = this.props;

        return (
            <MuiThemeProvider theme={theme}>
              <ValidateAuthentication />
            <Grid>
              {/* <Grid>
      
                <Navbar />
              </Grid> */}
              <Container className={classes.container}>
                  <CMUSection {...this.props}/>
              </Container>
            
            </Grid>
            </MuiThemeProvider>
        )
    }
}
export default withStyles(useStyles)(CMUSPhinx);
