import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Container, Paper, Box, Chip, FormControlLabel, Switch, FormControl, InputLabel, Select, MenuItem, TextField, MuiThemeProvider } from '@material-ui/core';
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from './Avatar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import DbConnection from './DbConnection';
import { theme } from './Constants/CustomTheme'
import ValidateAuthentication from './ValidateAuthentication';

const db = new DbConnection();
// const theme = createMuiTheme({
//     palette: {
//       primary: yellow,
//       secondary: red
//     },

//         overrides: {
//             MuiButton: {
//                 borderRadius: 0,
//                 margin: 5,
//                 '&:hover': {
//                     background: '#fdc800',
//                     color: '#002147',
//                     borderColor: '#fdc800',
//                 }
//             }
//         },
//     }
// );

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: 1,
        minWidth: 120,
    },

    menuButton: {
        marginRight: 2,
    },
    title: {
        flexGrow: 1,
    },
    container: {
        paddingTop: 5,
    },
    paperBlock: {
        padding: 10,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    btn: {
        borderRadius: 0,
        margin: 5,
        '&:hover': {
            background: '#fdc800',
            color: '#002147',
            borderColor: '#fdc800',
        },
    }
});

class TextSentenceSign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openCircleLoading: false,
            wdblist: [],
            langcol: 'engw',
            wordlist: [],
            statusMsg: 'Idle',
            cIndex: 0,
            maxIndex: 0,
            currentWord: "",
            customInput: "",
            islwords: ["idle"],
            lang: 'e',
            // langv: true,
            menulist1: [],
            menulist2: [],
            menulist3: [],
            menu1: "",
            menu2: "",
            menu3: "",

        }
    }
    componentDidMount() {
        this.hiddenTextEvent();
        var lang = this.props.match.params?.word ? this.props.match.params.word : 'e';
        this.setState({
            lang:lang,
        });
        this.setLanguage(lang);
        // this.setLang();


    }

    load = async (params) => {
        if (params === 'e') {
            this.setState({
                langcol: 'engw',
            })
        }
        else if (params === 'p') {
            this.setState({
                langcol: 'pbw',
            })
        }
        else if (params === 'h') {
            this.setState({
                langcol: 'hiw',
            })
        }

        var result2 = await db.GetWordList(params);
        if (result2 === '') {
            console('exit');
            return;
        }
        else {
            this.setState({
                wdblist: result2,
            });
        }
    }

    handleClose = () => {
        this.setState({
            openCircleLoading: false,
        });
    };
    handleToggle = () => {
        this.setState({
            openCircleLoading: !this.state.openCircleLoading,
        });
    };



    getISL = async (lang) => {
        this.setState({
            lang: lang
        });

        // var sentence = this.state.menu1 + " " + this.state.menu2 + " " + this.state.menu3;

        var result = await db.GetTextSentenceSign(lang, this.state.customInput);
        if (result === '') {
            console('exit');
            return;
        }

        console.log(JSON.stringify(result));
        this.setState({
            data: result,
            wordlist: result['data'],
            islwords: result['PoSTag'],
        });

        this.get();

    }

    get = () => {
        const data = this.state.data;

        var txt = document.getElementsByClassName('txtaSiGMLText');
        txt[0].innerHTML = data.SiGML;

        var btn = document.getElementsByClassName('bttnPlaySiGMLText');
        btn[0].click();

        this.setState({
            statusMsg: "Playing",
        });

    }

    nextSign = () => {
        const data = this.state.data;
        if (this.state.cIndex <= this.state.maxIndex) {
            const cur = data[this.state.cIndex];
            this.setState({
                currentWord: data[this.state.cIndex].word,
            })
            var btn = document.getElementsByClassName('bttnPlaySiGMLText');
            var txt = document.getElementsByClassName('txtaSiGMLText');
            if (data.length > 0) {
                txt[0].innerHTML = cur.SiGML;
            }
            btn[0].click();
        }
    }
    setLanguage= (e) => {
        this.setState({
            //langv: !this.state.langv,
            lang: e,
            customInput: "",
            menu1: "",
            menu2: "",
            menu3: "",
        });
        this.setLang(e);
        this.load(e);
    }
    setLang = (lang) => {

        if (lang === 'h') {
            // document.getElementById('eweb').style.visibility = "hidden";
            // document.getElementById('hweb').style.visibility = "visible";
            this.state.menulist1 = ['मैं', 'हम', 'तुम', 'मेरा', 'वह', 'यह'];
            this.state.menulist2 = ['लड़का', 'लड़की', 'अभिनेता', 'अभिनेत्री', 'लेखक', 'बच्चा', 'बुरा', 'सुन्दर', 'बंगाली', 'पुरुष', 'बेहतर', 'महिला', 'शिक्षक', 'छात्र', 'पुलिसकर्मी', 'ड्राइवर', 'क्लर्क', 'डॉक्टर', 'मैकेनिक'];
            this.state.menulist3 = ['है', 'हूँ', 'था', 'होगा', 'थे'];
        }
        else if (lang === 'p') {
            // document.getElementById('eweb').style.visibility = "hidden";
            // document.getElementById('hweb').style.visibility = "visible";
            this.state.menulist1 = ['ਉਹ', 'ਇਹ', 'ਮੇਰਾ', 'ਅਸੀ', 'ਤੁਸੀਂ'];
            this.state.menulist2 = ['ਮੇਜ', 'ਸੇਬ', 'ਕੇਲਾ', 'ਲਾਲ', 'ਬਾਸਕਟਬਾਲ'];
            this.state.menulist3 = ['ਹੈ', 'ਸੀ', 'ਹਨ', 'ਸਨ', 'ਹੋਵੇਗਾ', 'ਜਾਵੇਗਾ'];
        }
        else {
            // document.getElementById('eweb').style.visibility = "visible";
            // document.getElementById('hweb').style.visibility = "hidden";
            this.state.menulist1 = ['I', 'We', 'You', 'They', 'He', 'She', 'It'];
            this.state.menulist2 = ['is', 'am', 'are', 'was', 'were', 'has', 'have', 'had', 'will', 'shall', 'would', 'should', 'has been', 'have been', 'had been', 'will be', 'would be', 'shall be', 'should be'];
            this.state.menulist3 = ['boy', 'girl', 'actor', 'actress', 'author', 'baby', 'beautiful', 'bangali', 'bad', 'better', 'man', 'woman', 'teacher', 'student', 'policeman', 'driver', 'clerk', 'carpenter', 'doctor', 'mechanic'];
        }

    }


    hiddenTextEvent = () => {
        var el = document.getElementById('gloss');
        const self = this;
        el.addEventListener("change", function () {
            self.setState({
                statusMsg: el.value,
            })
        });
    }



    render() {
        const { classes } = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                <ValidateAuthentication />

                <Box height="100vh" flex={1} display="flex" flexDirection="column">
                    {/* <Grid>
                        <Navbar />
                    </Grid> */}

                    <Grid container spacing={2}>
                        <Grid item md={8} >
                            <Card >
                                <CardActions >
                                    <div style={{ width: "100%", textAlign: "center", margin: 10, padding: 10 }}>

                                        <Grid container spacing={3} style={{ margin: 10 }}>

                                            <Grid item sm={5} style={{ textAlign: 'right' }}>
                                                <Button variant={(this.state.lang == 'p') ? "contained" : "outlined"} color="secondary" onClick={() => { this.setLanguage('p') }}>ਪੰਜਾਬੀ</Button>

                                            </Grid>
                                            <Grid item sm={2} >

                                                <Button variant={(this.state.lang == 'h') ? "contained" : "outlined"} color="secondary" onClick={() => { this.setLanguage('h') }}>हिंदी</Button>
                                            </Grid>
                                            <Grid item sm={5} style={{ textAlign: 'left' }}>
                                                <Button variant={(this.state.lang == 'e') ? "contained" : "outlined"} color="secondary" onClick={() => { this.setLanguage('e') }}>English</Button>
                                            </Grid>

                                        </Grid>
                                        {/* <Grid component="label" container alignItems="center" spacing={1}>
                                            <Grid item><Typography variant="h6">Language: </Typography></Grid>
                                            <Grid>English</Grid>
                                            <Grid item>
                                                <Switch size="small" onChange={() => { this.changeLanguage() }} />
                                            </Grid>
                                            <Grid item>Hindi</Grid>
                                        </Grid> */}
                                        {/* <Speech /> */}
                                        <Backdrop className={classes.backdrop} open={this.state.openCircleLoading} onClick={this.handleClose}>
                                            <CircularProgress color="secondary" />
                                        </Backdrop>
                                    </div>
                                </CardActions>
                                <CardContent>
                                    <Grid>
                                        <Box padding={2}>
                                            <Typography variant="h5" >Type your own text to translate in ISL </Typography>

                                            <TextField
                                                variant="outlined"
                                                value={this.state.customInput}
                                                onChange={
                                                    (e) => {
                                                        this.setState({
                                                            customInput: e.target.value,
                                                        })
                                                    }
                                                }
                                                fullWidth
                                                placeholder="Enter your text through keyboard"
                                            />
                                        </Box>
                                        <Box  textAlign={"center"}>
                                            <Box display={"inline"} margin={5}>
                                        <Typography variant="h5" display='inline'>Or Select from the List: </Typography>
                                        </Box>

                                            <Box  display={"inline"} >

                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="menulabel1">Select</InputLabel>
                                                <Select
                                                    labelId="menulabel1"
                                                    id="menu1"
                                                    value={this.state.menu1}
                                                    onChange={
                                                        (e) => {
                                                            this.setState({
                                                                menu1: e.target.value,
                                                                customInput: e.target.value + " " + this.state.menu2 + " " + this.state.menu3,

                                                            });
                                                        }}
                                                >
                                                    {this.state.menulist1.map(row => (
                                                        <MenuItem value={row}>{row}</MenuItem>))}

                                                </Select>
                                            </FormControl>



                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="menulabel2">Select</InputLabel>
                                                <Select
                                                    labelId="menulabel2"
                                                    id="menu2"
                                                    value={this.state.menu2}
                                                    onChange={
                                                        (e) => {
                                                            this.setState({
                                                                menu2: e.target.value,
                                                                customInput: this.state.menu1 + " " + e.target.value + " " + this.state.menu3,

                                                            })
                                                        }
                                                    }
                                                >
                                                    {this.state.menulist2.map(row => (
                                                        <MenuItem value={row}>{row}</MenuItem>))}
                                                </Select>
                                            </FormControl>


                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="menulabel3">Select</InputLabel>
                                                <Select
                                                    labelId="menulabel3"
                                                    id="menu3"
                                                    value={this.state.menu3}
                                                    onChange={
                                                        (e) => {
                                                            this.setState({
                                                                menu3: e.target.value,
                                                                customInput: this.state.menu1 + " " + this.state.menu2 + " " + e.target.value,
                                                            });

                                                        }}
                                                >
                                                    {this.state.menulist3.map(row => (
                                                        <MenuItem value={row}>{row}</MenuItem>))}
                                                </Select>
                                            </FormControl>

                                            </Box>


                                        </Box>

                                        <Box>
                                            {this.state.customInput}
                                        </Box>

                                    </Grid>
                                    <Grid >
                                        {/* data: {JSON.stringify(this.state.wordlist)}<br/> */}
                                        <ol>
                                            <>  {this.state.wordlist.map(row => (
                                                row.map(sub_row => (
                                                    <li
                                                    >{sub_row['word'] + " - " + sub_row['text'] + " - " + sub_row['pos']} </li>))
                                            ))}
                                            </>
                                        </ol>
                                    </Grid>

                                </CardContent>
                                <CardContent>
                                    <div>

                                        <input id="gloss" type="hidden"></input>


                                        <Button
                                            id="eweb"
                                            onClick={() => { this.getISL(this.state.lang) }}
                                            variant="contained"
                                            color="primary">
                                            Translate
                                        </Button>
                                    </div>
                                </CardContent>
                                <CardContent>
{/* 
                                    <>  {this.state.wdblist.map((row, index) => (

                                        <Button className={classes.btn} key={index} variant="outlined">{row[this.state.langcol]}</Button>
                                    ))}
                                    </> */}

                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4}>
                            <Paper>

                                <Avatar />
                                <Grid>

                                    <Alert severity="success" variant="outlined" icon={false}>
                                        <AlertTitle><strong>Current :</strong>  {this.state.statusMsg}</AlertTitle>
                                        <AlertTitle><strong>POS Tag :</strong>  {

                                            <>  {this.state.islwords.map(word => (
                                                <Typography
                                                    key={word['text']}
                                                    style={{ display: (word['text'] === this.state.statusMsg) ? "inline-block" : "none" }}
                                                >{word['pos']} </Typography>
                                            ))}
                                            </>

                                        }</AlertTitle>
                                        <>  {this.state.islwords.map(word => (
                                            <Typography
                                                key={word['text']}
                                                display="inline"
                                                color={(word['text'] === this.state.statusMsg) ? "secondary" : "primary"}
                                            >{word['text']} </Typography>
                                        ))}
                                        </>
                                    </Alert>
                                </Grid>

                            </Paper>
                        </Grid>
                    </Grid>


                </Box>
            </MuiThemeProvider >

        );
    }
}
export default withStyles(useStyles, { withTheme: true })(TextSentenceSign);