import { Avatar, Card, CardContent, CardHeader, Container, createMuiTheme, Grid, MuiThemeProvider, Paper, Toolbar, Typography, withStyles } from '@material-ui/core'
import { deepOrange, teal } from '@material-ui/core/colors';
import React, { Component } from 'react'
import CMUSection from './CMUSection';
import Navbar from './Navbar';
import TextWordList from './TextWordList';
import AvatarPlayer from './Avatar';
import {theme} from './Constants/CustomTheme'
import ValidateAuthentication from './ValidateAuthentication';

// const theme = createMuiTheme(
//     {
//         palette: {
//             primary: teal,
//             secondary: deepOrange,
//         },
//     }
// )

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        paddingTop: 5,
    },
    paperBlock: {
        padding: 10,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});
class WordTextSign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CardTitle: 'Click on the category and select word',
        };
    }
    render() {
        const { classes } = this.props;

        return (
            <MuiThemeProvider theme={theme}>
              <ValidateAuthentication />

                <Grid>
                    {/* <Grid>
                        <Navbar />
                    </Grid> */}
                    <Container className={classes.container}>
                        <Grid container spacing={2}>
                            <Grid item md={7}>
                                <Card>
                                    <CardHeader
                                        title={this.state.CardTitle}
                                        avatar={
                                            <Avatar aria-label="recipe" className={classes.avatar}>
                                                T
                            </Avatar>
                                        }>
                                    </CardHeader>
                                    <CardContent style={{ textAlign: 'center' }}>
                                        <Grid container spacing={3} style={{ margin: 10 }}>
                                            <TextWordList />

                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={5}>
                                <Card>
                                    <CardContent>
                                        <AvatarPlayer />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </MuiThemeProvider>
        )
    }
}
export default withStyles(useStyles)(WordTextSign);
