import { Box, Button, Container, Fab, Grid, IconButton, Paper, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import {  MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import './Constants/style.css'
import CachedIcon from '@material-ui/icons/Cached';
import Avatar from './Avatar';
import API from './Constants/API';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DbConnection from './DbConnection';
import { theme } from './Constants/CustomTheme'
import ValidateAuthentication from './ValidateAuthentication';
import InfoIcon from '@material-ui/icons/Info';
const db = new DbConnection();
var category = require('./Constants/category');


const useStyles = theme => ({
    paper: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 15,
        paddingBottom: 15,

    },
    btn: {
        borderRadius: 0,
        margin: 5,
        '&:hover': {
            background: '#fdc800',
            color: '#002147',
            borderColor: '#fdc800',
        },
    }

});

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            wlist: [],
            catList: ['School', 'Colors', 'Days', 'Months', 'Fruits', 'Health', 'States'],
            langcol: 'engw',
            lang: 'e',
            searchText: '',
            hiw: '',
            pbw: '',
            engw: '',

        };
    }
    componentDidMount() {
        var lang = this.props.match.params.lang;
        this.setState({
            lang:lang,
            catList: category.data,
        })
        this.load(lang);

        console.log(this.props.match.params.lang);
        console.log(this.props.match.params?.word || 0);

    }

    load = async (params) => {
        console.log(category.data);

        if (params === 'e') {
            this.setState({
                langcol: 'engw',
            })
        }
        else if (params === 'p') {
            this.setState({
                langcol: 'pbw',

            })
        }
        else if (params === 'h') {
            this.setState({
                langcol: 'hiw',

            })
        }

        var result = await db.GetRandomWords(params);

        if (result === '') {
            console('exit');
            return;
        }
        else {
            this.setState({
                list: result,
            });
        }


        var result2 = await db.GetWordList(params);
        if (result2 === '') {
            console('exit');
            return;
        }
        else {
            this.setState({
                wlist: result2,
            });
        }
    }
    searchWord = async () => {
        if (this.state.searchText === '') {
            alert('Please enter text before search');
            return;
        }
        var result = await db.SearchWord(this.state.lang, this.state.searchText);

        if (result === '') {
            console('exit');
            return;
        }
        else {
            this.setState({
                list: result,
            });
        }
    }

    loadSignAction = async (wid) => {
        var result = await db.GetSignByWID(wid);
        this.setState({
            engw: '',
            hiw: '',
            pbw: '',
            feature:'',
        })
        if (result === '') {
            console('exit');
            return;
        }
        else {
            this.setState({
                data: result,
                engw: result.English,
                hiw: result.Hindi,
                pbw: result.Punjabi,
                feature: (result.Feature !== "")?'Non-Manual':'',

            });
            this.playSign();
        }
    }
    playSign = () => {
        const data = this.state.data;

        var txt = document.getElementsByClassName('txtaSiGMLText');
        txt[0].innerHTML = data.SiGML;

        var btn = document.getElementsByClassName('bttnPlaySiGMLText');
        btn[0].click();

        this.setState({
            statusMsg: "Playing",
        });

    }
    wordByTag = async (params) => {
        //window.scrollTo({ top: 0, behavior: 'smooth'});
        document.body.scrollTop=0;
                var result = await db.GetWordsByTag(params, this.state.lang);

        if (result === '') {
            console('exit');
            return;
        }
        else {
            this.setState({
                list: result,
            });
            
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <MuiThemeProvider theme={theme}>
              <ValidateAuthentication />

                <Container>

                    <Grid container spacing={5}>
                        <Grid item md={3}>

                            <div className="sidebar">

                                <div className="sidebar-box">
                                    <div className="sidebar-box-inner">
                                        <h3 className="sidebar-title">Find your Word</h3>
                                        <div >
                                            <Grid container spacing={2}>


                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={this.state.wlist}
                                                        inputValue={
                                                            this.state.searchText
                                                        }
                                                        onInputChange={
                                                            (e, val) => {
                                                                console.log(val);
                                                                this.setState({
                                                                    searchText: val
                                                                })
                                                            }
                                                        }
                                                        getOptionLabel={option => (option.engw ? option.engw : "")}

                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} fullWidth label="Education" variant="outlined" />}
                                                    />
                                                </Grid>

                                                <Grid item >
                                                    <button className="sidebar-search-btn disabled" onClick={() => { this.searchWord() }} >Search</button>
                                                    {/* <Fab  aria-label="Please Install Google Input Tool in Chrome to Type in Punjabi" onClick={()=> {window.open('https://chrome.google.com/webstore/detail/google-input-tools/mclkkofklkfljcocdinagocijmpgbhab?hl=en','_blank');}} >
                                                        <IconButton>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Fab> */}
                                                </Grid>
                                            </Grid>

                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar-box">
                                    <div className="sidebar-box-inner">
                                        <h3 className="sidebar-title">Categories</h3>
                                        <ul className="sidebar-categories">
                                            <>  {this.state.catList.map((row, index) => (
                                                <li key={index} onClick={() => { this.wordByTag(row.EWord) }}>{
                                                    (this.state.lang==='e')?row.EWord:(this.state.lang==='p')?row.PWord:row.HWord
                                                }</li>
                                            ))}
                                            </>
                                        </ul>
                                    </div>
                                </div>

                            </div>


                        </Grid>
                        <Grid item md={9}>


                            <div className="courses-page-top-area">
                                <div className="courses-page-top-left">
                                    <p>Showing Random 40 results</p>
                                </div>
                                <div className="courses-page-top-right">
                                    <ul>
                                        <li className="active"><Button startIcon={<CachedIcon />} variant="outlined" onClick={() => { this.load(this.state.lang) }}> Random </Button> <a href="#gried-view" data-toggle="tab" aria-expanded="false"><i className="fa fa-th-large"></i></a></li>
                                    </ul>
                                </div>
                            </div>



                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="gried-view">
                                    <Grid container spacing={3}>
                                        <Grid item md={6} >
                                            <Paper className={classes.paper}>
                                                <>  {this.state.list.map((row, index) => (

                                                    <Button className={classes.btn} key={index} variant="outlined" onClick={() => { this.loadSignAction(row['wid']) }}>{row[this.state.langcol]}</Button>
                                                ))}
                                                </>

                                            </Paper>
                                        </Grid>
                                        <Grid item md={6} >
                                            <Paper className={classes.paper}>
                                                <Avatar />
                                                <Box padding={5} textAlign="center">
                                                    <Button variant="contained" color="secondary">
                                                        {this.state.engw}
                                                    </Button>
                                                    <Button variant="contained" color="secondary">
                                                        {this.state.pbw}
                                                    </Button>
                                                    <Button variant="contained" color="secondary">
                                                        {this.state.hiw}
                                                    </Button>
                                                </Box>
                                                <Box padding={5} textAlign="center">
<Button variant="contained" color="primary">
    {this.state.feature}
</Button>
                                                    </Box>
                                            </Paper>

                                        </Grid>
                                    </Grid>



                                </div>

                            </div>


                            {/* <div>
                    <h1>welcome</h1>
                    <h2>{this.props.match.params.lang}</h2>
                    <h2>{this.props.match.params.word}</h2>

                    <button className="sidebar-search-btn disabled" type="submit" value="Login">Search Course</button>
                </div> */}
                        </Grid>


                    </Grid>
                </Container>
            </MuiThemeProvider>
        )
    }
}
export default withStyles(useStyles)(Search);
