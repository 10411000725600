import React, { useState } from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { IconButton, Button, Grid, Fab } from '@material-ui/core';
import MicIcon from '@material-ui/icons/Mic';



function setLang(lang){
  if (lang === 'e') {
    document.getElementById('engspk').style.display = "block";
    document.getElementById('hispk').style.display = "none";
    console.log("english");

  }
  else if (lang === 'h') {
    document.getElementById('engspk').style.display = "none";
    document.getElementById('hispk').style.display = "block";
    console.log("hindi");
  }
}
const Dictaphone = (props) => {
  console.log(props);


  const { transcript, listening } = useSpeechRecognition();
  const [on, setIcon] = useState(false);
  //transcript="Baby Actor";
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null
  }
  if (!listening) {
    if (on != true) {
      setIcon(true);

    }
    console.log("stoped");
  }
const speakEng = () => {
  //SpeechRecognition.abortListening();
  SpeechRecognition.startListening({ language: 'en-IN' })
}
const speakHin = () =>{
  //SpeechRecognition.abortListening();
  SpeechRecognition.startListening({ language: 'hi-IN' })
}

  return (
    <div>
      <Grid container justify="center">
        <Grid item>
          <div style={(props.lang==='e')?{display:"block"}:{display:"none"}}>

          <Fab style={{ background: "#fdc800"}} id="engspk" >

            <IconButton aria-label="delete" onClick={() => { speakEng() }}>
              <MicIcon fontSize="large" />
            </IconButton>
          </Fab>
          </div>
          <div style={(props.lang==='h')?{display:"block"}:{display:"none"}}>

          <Fab style={{ background: "#fdc800" }} id="hispk">

            <IconButton aria-label="delete" onClick={() => { speakHin() }}>
              <MicIcon fontSize="large" />
            </IconButton>
          </Fab>
          </div>
        </Grid>
      </Grid>
      {/* <Button >English</Button>
      <Button >Hindi</Button> */}
      {/* <button onClick={SpeechRecognition.stopListening}>Stop</button> */}
      {/* <button onClick={resetTranscript}>Reset</button> */}
      <p id="transcript">{transcript} </p>

      
    </div>
    
  )



}
export default Dictaphone