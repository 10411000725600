//import liraries
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, FormControlLabel, Switch, Paper, Slide, Grow, Typography } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';

// "initCamera":       [ 0, 0.23, 3.24, 5, 18, 30, -1, -1 ],

const useStyles = theme => ({
    btn: {
        margin: theme.spacing(1),
    },
    slider: {
        padding: 10,
        //width: 300,
    },
    avcontainer: {
        textAlign: 'center',
        marginTop: 10,
    }

});



class Avatar extends Component {

    //     
    // bttnSpeedUp
    // bttnSpeedReset
    constructor(props) {
        super(props);
        this.state = {
            value: 3,
            checked: false,
        }
    }
    handleChange = (e, val) => {


        if (val < this.state.value) {
            var c = this.state.value - val;

            var el = document.getElementsByClassName("bttnSpeedDown");
            for (var i = 0; i < c; i++) {
                el[0].click();
            }

            this.setState({
                value: val
            })

        }
        else if (val > this.state.value) {
            var el1 = document.getElementsByClassName("bttnSpeedUp");
            var c1 = val - this.state.value;

            for (var i = 0; i < c1; i++) {
                el1[0].click();
            }

            this.setState({
                value: val
            })

        }

    }

    playAgain = () => {
        var btn = document.getElementsByClassName('bttnPlaySiGMLText');
        btn[0].click();

    }
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.avcontainer}>

                <Grid container spacing={2} className={classes.slider} >
                    <Grid item xs={4}>
                        <Typography >Speed: </Typography>
                    </Grid>
                    <Grid item>
                        <DirectionsWalkIcon />
                    </Grid>
                    <Grid item xs>
                        <Slider
                            value={this.state.value}
                            onChange={(e, val) => this.handleChange(e, val)}
                            defaultValue={3}
                            min={1}
                            max={5}
                        />
                    </Grid>
                    <Grid item>
                        <DirectionsRunIcon />
                    </Grid>
                   
                </Grid>


                <div style={{ width: 300, height: 350, display: 'inline-block' }} className="img-thumbnail">

                    <div className="divCtrlPanel" id="divCtrlPanel" style={{ display: 'none' }}>

                        Avatar: <span className="CWASAAvMenu av0"></span>

                        Speed (log<sub>2</sub> scale): <span className="CWASASpeed av0"></span>

                        SiGML URL:
                        <div className="CWASASiGMLURL av0"></div>

                        SiGML Text:
                        <div className="CWASASiGMLText av0"></div>

                        <div className="CWASAPlay av0"></div>
                        <span className="CWASAPlayExtra av0"></span>

                        Frames:
                        <span className="CWASAFrames av0"></span>

                        <div className="CWASAProgress av0"></div>
                        <div className="CWASAStatus av0" id="st"></div>
                    </div>
                    <div className="CWASAAvatar av0" align="center" style={{ display: 'block' }}></div>
                    
                </div>
                <Grid style={{margin:5}}>
                        <Button onClick={this.playAgain} variant="contained" color="secondary">Replay</Button>
                    </Grid>

            </div>
        );
    }
}
//make this component available to the app
export default withStyles(useStyles)(Avatar);
