module.exports = {
    "data":
        [
            {
                "WID": "296",
                "EWord": "Although it was raining, we had the picnic.",
                "PWord": "ਹਾਲਾਂਕਿ ਬਾਰਸ਼ ਹੋ ਰਹੀ ਸੀ, ਪਰ ਅਸੀਂ ਪਿਕਨਿਕ ਲਈ ਗਏ ਸੀ ",
                "HWord": "हालाँकि बारिश हो रही थी, लेकिन हम पिकनिक पर गए थे।",
                "VideoPath": "/video/Although_it_was_raining.mp4",
            },
            {
                "WID": "297",
                "EWord": "Book is lying on the table.",
                "PWord": "ਕਿਤਾਬ ਮੇਜ਼ ਉੱਤੇ ਪਈ ਹੈ",
                "HWord": "पुस्तक मेज पर पड़ी है।",
                "VideoPath": "/video/Book_is_lying_on_the_table.mp4",
            },
            {
                "WID": "298",
                "EWord": "Come to my office on Monday.",
                "PWord": "ਸੋਮਵਾਰ ਨੂੰ ਮੇਰੇ ਦਫਤਰ ਆਓ",
                "HWord": "सोमवार को मेरे कार्यालय में आओ।",
                "VideoPath": "/video/Come_to_my_office_on_monday.mp4",
            },
            {
                "WID": "299",
                "EWord": "Every student likes the teacher.",
                "PWord": "ਹਰ ਵਿਦਿਆਰਥੀ ਅਧਿਆਪਕ ਨੂੰ ਪਸੰਦ ਕਰਦਾ ਹੈ",
                "HWord": "हर छात्र शिक्षक को पसंद करता है।",
                "VideoPath": "/video/Every_student_likes.mp4",
            },
            {
                "WID": "300",
                "EWord": "Father reads correctly.",
                "PWord": "ਪਿਤਾ ਜੀ ਸਹੀ ਪੜ੍ਹਦੇ ਹਨ",
                "HWord": "पिता सही से पढ़ते हैं।",
                "VideoPath": "/video/Father_reads_correctly.mp4",
            },
            {
                "WID": "301",
                "EWord": "I am Dancing.",
                "PWord": "ਮੈਂ ਨੱਚ ਰਿਹਾ ਹਾਂ।",
                "HWord": "मैं नाच रहा हूँ।",
                "VideoPath": "/video/I_am_Dancing.mp4",
            },
            {
                "WID": "302",
                "EWord": "I am enjoying.",
                "PWord": "ਮੈਂ ਅਨੰਦ ਲੈ ਰਿਹਾ ਹਾਂ",
                "HWord": "मैं आनंद ले रहा हूँ।",
                "VideoPath": "/video/I_am_enjoying.mp4",
            },
            {
                "WID": "303",
                "EWord": "I am happy.",
                "PWord": "ਮੈਂ ਖੁਸ਼ ਹਾਂ",
                "HWord": "मैं खुश हूँ।",
                "VideoPath": "/video/I_am_happy.mp4",
            },
            {
                "WID": "304",
                "EWord": "I am looking forward to meet you.",
                "PWord": "ਮੈਂ ਤੁਹਾਨੂੰ ਮਿਲਣ ਦੀ ਉਮੀਦ ਕਰ ਰਿਹਾ ਹਾਂ",
                "HWord": "मैं तुमसे मिलने की राह देख रहा हूँ।",
                "VideoPath": "/video/I_am_looking_forward_to_meet_you.mp4",
            },
            {
                "WID": "305",
                "EWord": "I am swimming.",
                "PWord": "ਮੈਂ ਤੈਰ ਰਿਹਾ ਹਾਂ",
                "HWord": "मैं तैर रहा हूँ।",
                "VideoPath": "/video/I_am_swimming.mp4",
            },
            {
                "WID": "306",
                "EWord": "I Enjoyed the movie.",
                "PWord": "ਮੈਂ ਫਿਲਮ ਦਾ ਅਨੰਦ ਲਿਆ",
                "HWord": "मैंने फिल्म का आनंद लिया।",
                "VideoPath": "/video/I_enjoyed_the_movie.mp4",
            },
            {
                "WID": "307",
                "EWord": "I like icecream very much.",
                "PWord": "ਮੈਨੂੰ ਆਈਸਕ੍ਰੀਮ ਬਹੁਤ ਪਸੰਦ ਹੈ",
                "HWord": "मुझे आइसक्रीम बहुत पसंद है।",
                "VideoPath": "/video/I_like_icecream.mp4",
            },
            {
                "WID": "308",
                "EWord": "I lived in the United States.",
                "PWord": "ਮੈਂ ਸੰਯੁਕਤ ਰਾਜ ਵਿਚ ਰਹਿੰਦਾ ਸੀ",
                "HWord": "मैं संयुक्त राज्य में रहता था।",
                "VideoPath": "/video/I_lived_in_the_United_states.mp4",
            },
            {
                "WID": "309",
                "EWord": "I love cooking.",
                "PWord": "ਮੈਨੂੰ ਖਾਣਾ ਪਕਾਉਣਾ ਪਸੰਦ ਹੈ",
                "HWord": "मुझे खाना पकाना पसंद है।",
                "VideoPath": "/video/I_love_cooking.mp4",
            },
            {
                "WID": "310",
                "EWord": "I love driving.",
                "PWord": "ਮੈਨੂੰ ਗੱਡੀ ਚਲਾਉਣਾ ਪਸੰਦ ਹੈ",
                "HWord": "मुझे ड्राइविंग करना पसंद है।",
                "VideoPath": "/video/I_love_driving.mp4",
            },
            {
                "WID": "311",
                "EWord": "I love painting.",
                "PWord": "ਮੈਨੂੰ ਪੇਂਟਿੰਗ ਪਸੰਦ ਹੈ",
                "HWord": "मुझे पेंटिंग करना पसंद है।",
                "VideoPath": "/video/I_love_painting.mp4",
            },
            {
                "WID": "312",
                "EWord": "I love singing.",
                "PWord": "ਮੈਨੂੰ ਗਾਉਣਾ ਪਸੰਦ ਹੈ",
                "HWord": "मुझे गाना गाना पसंद है।",
                "VideoPath": "/video/I_love_singing.mp4",
            },
            {
                "WID": "313",
                "EWord": "I love you.",
                "PWord": "ਮੈਂ ਤੁਹਾਨੂੰ ਪਿਆਰ ਕਰਦਾ ਹਾਂ",
                "HWord": "मैं तुमसे प्यार करता हूँ।",
                "VideoPath": "/video/I_love_you.mp4",
            },
            {
                "WID": "314",
                "EWord": "I must call him immediately.",
                "PWord": "ਮੈਨੂੰ ਉਸਨੂੰ ਤੁਰੰਤ ਬੁਲਾ ਲੈਣਾ ਚਾਹੀਦਾ ਹੈ",
                "HWord": "मुझे उसे तुरंत बुलाना चाहिए।",
                "VideoPath": "/video/I_must_call_him.mp4",
            },
            {
                "WID": "315",
                "EWord": "I visited Niagara Falls last weekend.",
                "PWord": "ਮੈਂ ਪਿਛਲੇ ਹਫਤੇ ਨਿਆਗਰਾ ਫਾਲਸ ਦਾ ਦੌਰਾ ਕੀਤਾ.",
                "HWord": "मैंने पिछले सप्ताहांत में नियाग्रा फॉल्स का दौरा किया।",
                "VideoPath": "/video/I_visited_Niagara_falls.mp4",
            },
            {
                "WID": "316",
                "EWord": "Movie was not seen by me.",
                "PWord": "ਫਿਲਮ ਮੇਰੇ ਦੁਆਰਾ ਨਹੀਂ ਵੇਖੀ ਗਈ ਸੀ.",
                "HWord": "मेरे द्वारा फिल्म नहीं देखी गई।",
                "VideoPath": "/video/Movie_was_not_seen_by_me.mp4",
            },
            {
                "WID": "317",
                "EWord": "She can drive.",
                "PWord": "ਉਹ ਗੱਡੀ ਚਲਾ ਸਕਦੀ ਹੈ।",
                "HWord": "वह गाड़ी चला सकती है।",
                "VideoPath": "/video/She_can_drive.mp4",
            },
            {
                "WID": "318",
                "EWord": "She filled the bottel with milk.",
                "PWord": "ਉਸਨੇ ਬੋਤਲ ਨੂੰ ਦੁੱਧ ਨਾਲ ਭਰ ਦਿੱਤਾ.",
                "HWord": "उसने दूध से बोतल भरी।",
                "VideoPath": "/video/She_filled_bottel_with_milk.mp4",
            },
            {
                "WID": "319",
                "EWord": "She was bored in the class.",
                "PWord": "ਉਸਦੀ ਕਲਾਸ ਵਿਚ ਦਿਲਚਸਪੀ ਨਹੀਂ ਸੀ ",
                "HWord": "वह कक्षा में ऊब गया था।",
                "VideoPath": "/video/She_was_bored_in_the_class.mp4",
            },
            {
                "WID": "320",
                "EWord": "Switch on the fan.",
                "PWord": "ਪੱਖਾ ਚਾਲੂ ਕਰੋ",
                "HWord": "पंखा चला दें",
                "VideoPath": "/video/Switch_on_the_fan.mp4",
            },
            {
                "WID": "321",
                "EWord": "The road was crossed by the child.",
                "PWord": "ਸੜਕ ਨੂੰ ਬੱਚੇ ਦੁਆਰਾ ਪਾਰ ਕੀਤਾ ਗਿਆ ਸੀ",
                "HWord": "बच्चे को सड़क पार कराई गई।",
                "VideoPath": "/video/The_road_was_crossed_by_the_child.mp4",
            },
            {
                "WID": "322",
                "EWord": "The women who works here is from Japan.",
                "PWord": "ਇੱਥੇ ਕੰਮ ਕਰਨ ਵਾਲੀਆਂ ਔਰਤਾਂ ਜਾਪਾਨ ਤੋਂ ਹਨ।",
                "HWord": "यहां काम करने वाली महिलाएं जापान की हैं।",
                "VideoPath": "/video/The_women _how works_here.mp4",
            },
            {
                "WID": "323",
                "EWord": "When I will arrive I will call you.",
                "PWord": "ਜਦੋਂ ਮੈਂ ਪਹੁੰਚਾਂਗਾ ਮੈਂ ਤੁਹਾਨੂੰ ਫੋਨ ਕਰਾਂਗਾ",
                "HWord": "जब मैं आऊंगा, मैं तुम्हें फोन करूंगा।",
                "VideoPath": "/video/When_i_will_arrive.mp4",
            },
            {
                "WID": "324",
                "EWord": "Where can I find a bank?",
                "PWord": "ਮੈਨੂੰ ਇੱਕ ਬੈਂਕ ਕਿੱਥੇ ਮਿਲ ਸਕਦਾ ਹੈ?",
                "HWord": "मुझे बैंक कहां मिल सकता है?",
                "VideoPath": "/video/Where_can_i_find_bank.mp4",
            },
            {
                "WID": "325",
                "EWord": "Why was it said by Sunil?",
                "PWord": "ਇਹ ਸੁਨੀਲ ਨੇ ਕਿਉਂ ਕਿਹਾ?",
                "HWord": "सुनील ने ऐसा क्यों कहा था?",
                "VideoPath": "/video/Why_was_it_said_by_Sunil.mp4",
            },
            {
                "WID": "326",
                "EWord": "Baby jumps fastly.",
                "PWord": "ਬੱਚਾ ਤੇਜ਼ੀ ਨਾਲ ਛਾਲ ਮਾਰਦਾ ਹੈ.",
                "HWord": "बच्चा तेजी से कूदता है।",
                "VideoPath": "/video/Baby jumps fastly.mp4",
            },
            {
                "WID": "327",
                "EWord": "Ball Is mine.",
                "PWord": " ਗੇਂਦ ਮੇਰੀ ਹੈ",
                "HWord": "गेंद मेरी है।",
                "VideoPath": "/video/Ball_is_mine.mp4",
            },
            {
                "WID": "328",
                "EWord": "Cars are mine.",
                "PWord": "ਕਾਰਾਂ ਮੇਰੀਆਂ ਹਨ.",
                "HWord": "कारें मेरी हैं।",
                "VideoPath": "/video/cars_are_mine.mp4",
            },
            {
                "WID": "329",
                "EWord": "Father reads correctly.",
                "PWord": "ਪਿਤਾ ਜੀ ਸਹੀ ਪੜ੍ਹਦੇ ਹਨ",
                "HWord": "पिता सही ढंग से पढ़ता है।",
                "VideoPath": "/video/Father reads correctly.mp4",
            },
            {
                "WID": "330",
                "EWord": "He has a book.",
                "PWord": "ਉਸ ਕੋਲ ਇੱਕ ਕਿਤਾਬ ਹੈ",
                "HWord": "उसके पास एक किताब है।",
                "VideoPath": "/video/He_has_a_book.mp4",
            },
            {
                "WID": "331",
                "EWord": "He has a toy.",
                "PWord": "ਉਸ ਕੋਲ ਇੱਕ ਖਿਡੌਣਾ ਹੈ",
                "HWord": "उसके पास एक खिलौना है।",
                "VideoPath": "/video/He_has_a_toy.mp4",
            },
            {
                "WID": "332",
                "EWord": "He is cutting a paper.",
                "PWord": "ਉਹ ਇੱਕ ਕਾਗਜ਼ ਕੱਟ ਰਿਹਾ ਹੈ",
                "HWord": "वह एक कागज काट रहा है।",
                "VideoPath": "/video/He_is_cutting_a_paper.mp4",
            },
            {
                "WID": "333",
                "EWord": "He is my friend.",
                "PWord": "ਉਹ ਮੇਰਾ ਦੋਸਤ ਹੈ",
                "HWord": "वह मेरा दोस्त है।",
                "VideoPath": "/video/He_is_my_friend.mp4",
            },
            {
                "WID": "334",
                "EWord": "He is writing with pen.",
                "PWord": "ਉਹ ਕਲਮ ਨਾਲ ਲਿਖ ਰਿਹਾ ਹੈ",
                "HWord": "वह कलम से लिख रहा है।",
                "VideoPath": "/video/He_is_writing_with_pen.mp4",
            },
            {
                "WID": "335",
                "EWord": "He loves cricket.",
                "PWord": "ਉਹ ਕ੍ਰਿਕਟ ਨੂੰ ਪਿਆਰ ਕਰਦਾ ਹੈ",
                "HWord": "उन्हें क्रिकेट से प्यार है।",
                "VideoPath": "/video/He_loves_cricket.mp4",
            },
            {
                "WID": "336",
                "EWord": "He loves eating.",
                "PWord": "ਉਹ ਖਾਣਾ ਪਸੰਦ ਕਰਦਾ ਹੈ",
                "HWord": "उसे खाना बहुत पसंद है।",
                "VideoPath": "/video/He_loves_eating.mp4",
            },
            {
                "WID": "337",
                "EWord": "I am runnning.",
                "PWord": "ਮੈਂ ਭੱਜ ਰਿਹਾ ਹਾਂ",
                "HWord": "मैं भाग रहा हूं।",
                "VideoPath": "/video/I_am_runnning.mp4",
            },
            {
                "WID": "338",
                "EWord": "I have five apples.",
                "PWord": "ਮੇਰੇ ਕੋਲ ਪੰਜ ਸੇਬ ਹਨ",
                "HWord": "मेरे पास पांच सेब हैं।",
                "VideoPath": "/video/I_have_five_apples.mp4",
            },
            {
                "WID": "339",
                "EWord": "I have many cars.",
                "PWord": "ਮੇਰੇ ਕੋਲ ਬਹੁਤ ਸਾਰੀਆਂ ਕਾਰਾਂ ਹਨ",
                "HWord": "मेरे पास कई कारें हैं।",
                "VideoPath": "/video/I_have_many_cars.mp4",
            },
            {
                "WID": "340",
                "EWord": "I have many friends.",
                "PWord": "ਮੇਰੇ ਬਹੁਤ ਸਾਰੇ ਦੋਸਤ ਹਨ",
                "HWord": "मेरे बहुत सारे मित्र हैं।",
                "VideoPath": "/video/I_have_many_friends.mp4",
            },
            {
                "WID": "341",
                "EWord": "I have three books.",
                "PWord": "ਮੇਰੇ ਕੋਲ ਤਿੰਨ ਕਿਤਾਬਾਂ ਹਨ",
                "HWord": "मेरे पास तीन किताबें हैं।",
                "VideoPath": "/video/I_have_three_books.mp4",
            },
            {
                "WID": "342",
                "EWord": "I have two eggs.",
                "PWord": "ਮੇਰੇ ਕੋਲ ਦੋ ਅੰਡੇ ਹਨ",
                "HWord": "मेरे पास दो अंडे हैं।",
                "VideoPath": "/video/I_have_two_eggs.mp4",
            },
            {
                "WID": "343",
                "EWord": "Ring is mine.",
                "PWord": "ਅੰਗੂਠੀ ਮੇਰੀ ਹੈ",
                "HWord": "अंगूठी मेरी है।",
                "VideoPath": "/video/Ring_is_mine.mp4",
            },
            {
                "WID": "344",
                "EWord": "She cooks quickly.",
                "PWord": "ਉਹ ਤੇਜ਼ੀ ਨਾਲ ਪਕਾਉਂਦੀ ਹੈ",
                "HWord": "वह जल्दी से खाना बनाती है।",
                "VideoPath": "/video/She_cooks_quickly.mp4",
            },
            {
                "WID": "345",
                "EWord": "She has a fish.",
                "PWord": "ਉਸ ਕੋਲ ਇੱਕ ਮੱਛੀ ਹੈ",
                "HWord": "उसके पास एक मछली है।",
                "VideoPath": "/video/She_has_a_fish.mp4",
            },
            {
                "WID": "346",
                "EWord": "She has a ring.",
                "PWord": "ਉਸ ਕੋਲ ਇੱਕ ਅੰਗੂਠੀ ਹੈ",
                "HWord": "उसके पास एक अंगूठी है।",
                "VideoPath": "/video/She_has_a_ring.mp4",
            },
            {
                "WID": "347",
                "EWord": "She is crying.",
                "PWord": "ਉਹ ਰੋ ਰਹੀ ਹੈ",
                "HWord": "वो रो रही है।",
                "VideoPath": "/video/She_is_crying.mp4",
            },
            {
                "WID": "348",
                "EWord": "She is listening.",
                "PWord": "ਉਹ ਸੁਣ ਰਹੀ ਹੈ",
                "HWord": "वह सुन रही है।",
                "VideoPath": "/video/She_is_listening.mp4",
            },
            {
                "WID": "349",
                "EWord": "She loves sleeping.",
                "PWord": "ਉਹ ਸੌਣਾ ਪਸੰਦ ਕਰਦੀ ਹੈ",
                "HWord": "वह सोना पसंद करती है।",
                "VideoPath": "/video/She_loves_sleeping.mp4",
            },
            {
                "WID": "350",
                "EWord": "Teacher writes neatly.",
                "PWord": "ਅਧਿਆਪਕ ਚੰਗੀ ਤਰ੍ਹਾਂ ਲਿਖਦਾ ਹੈ",
                "HWord": "शिक्षक बड़े अच्छे तरीके से लिखता है।",
                "VideoPath": "/video/Teacher writes neatly.mp4",
            },
            {
                "WID": "351",
                "EWord": "That is my book.",
                "PWord": "ਇਹ ਮੇਰੀ ਕਿਤਾਬ ਹੈ",
                "HWord": "यह मेरी पुस्तक है।",
                "VideoPath": "/video/That_is_my_book.mp4",
            },
            {
                "WID": "352",
                "EWord": "Tomorrow is my birthday.",
                "PWord": "ਕੱਲ ਮੇਰਾ ਜਨਮਦਿਨ ਹੈ",
                "HWord": "कल मेरा जन्मदिन है",
                "VideoPath": "/video/Tommorrow_is_my_birthday.mp4",
            },
            {
                "WID": "353",
                "EWord": "Toys are mine.",
                "PWord": "ਖਿਡੌਣੇ ਮੇਰੇ ਹਨ",
                "HWord": "खिलौने मेरे हैं।",
                "VideoPath": "/video/Toys_are_mine.mp4",
            },
            {
                "WID": "354",
                "EWord": "We are counting.",
                "PWord": "ਅਸੀਂ ਗਿਣ ਰਹੇ ਹਾਂ",
                "HWord": "हम गिन रहे हैं।",
                "VideoPath": "/video/We_are_counting.mp4",
            },
            {
                "WID": "355",
                "EWord": "We are jumping.",
                "PWord": "ਅਸੀਂ ਛਾਲਾਂ ਮਾਰ ਰਹੇ ਹਾਂ",
                "HWord": "हम कूद रहे हैं।",
                "VideoPath": "/video/We_are_jumping.mp4",
            },
            {
                "WID": "356",
                "EWord": "We are sharing.",
                "PWord": "ਅਸੀਂ ਸਾਂਝੇ ਕਰ ਰਹੇ ਹਾਂ",
                "HWord": "हम साझा कर रहे हैं।",
                "VideoPath": "/video/We_are_sharing.mp4",
            },
            {
                "WID": "357",
                "EWord": "We are studying.",
                "PWord": "ਅਸੀਂ ਪੜ੍ਹ ਰਹੇ ਹਾਂ",
                "HWord": "हम पढ़ रहे हैं।",
                "VideoPath": "/video/We_are_studying.mp4",
            },
            {
                "WID": "358",
                "EWord": "We are writing.",
                "PWord": "ਅਸੀਂ ਲਿੱਖ ਰਹੇ ਹਾਂ",
                "HWord": "हम लिख रहे हैं।",
                "VideoPath": "/video/We_are_writing.mp4",
            },
            {
                "WID": "359",
                "EWord": "We have cup.",
                "PWord": "ਸਾਡੇ ਕੋਲ ਪਿਆਲਾ ਹੈ",
                "HWord": "हमारे पास प्याला है।",
                "VideoPath": "/video/We_have_cup.mp4",
            },
            {
                "WID": "360",
                "EWord": "We have food.",
                "PWord": "ਸਾਡੇ ਕੋਲ ਭੋਜਨ ਹੈ",
                "HWord": "हमारे पास भोजन है।",
                "VideoPath": "/video/We_have_food.mp4",
            },
            {
                "WID": "361",
                "EWord": "We have kite.",
                "PWord": "ਸਾਡੇ ਕੋਲ ਪਤੰਗ ਹੈ",
                "HWord": "हमारे पास पतंग है ।",
                "VideoPath": "/video/We_have_kite.mp4",
            },
            {
                "WID": "362",
                "EWord": "We have money.",
                "PWord": "ਸਾਡੇ ਕੋਲ ਪੈਸਾ ਹੈ.",
                "HWord": "हमारे पास पैसा है।",
                "VideoPath": "/video/We_have_money.mp4",
            },
            {
                "WID": "363",
                "EWord": "We have paper.",
                "PWord": "ਸਾਡੇ ਕੋਲ ਕਾਗਜ਼ ਹਨ",
                "HWord": "हमारे पास कागज है।",
                "VideoPath": "/video/We_have_paper.mp4",
            },
            {
                "WID": "364",
                "EWord": "We have soap.",
                "PWord": "ਸਾਡੇ ਕੋਲ ਸਾਬਣ ਹੈ",
                "HWord": "हमारे पास साबुन है।",
                "VideoPath": "/video/We_have_soap.mp4",
            },
            {
                "WID": "365",
                "EWord": "We want bag.",
                "PWord": "ਸਾਨੂੰ ਬੈਗ ਚਾਹੀਦਾ ਹੈ",
                "HWord": "हमें बैग चाहिए।",
                "VideoPath": "/video/We_want_bag.mp4",
            },
            {
                "WID": "366",
                "EWord": "We want cloth.",
                "PWord": "ਸਾਨੂੰ ਕੱਪੜਾ ਚਾਹੀਦਾ ਹੈ",
                "HWord": "हमें कपड़ा चाहिए।",
                "VideoPath": "/video/We_want_cloth.mp4",
            },
            {
                "WID": "367",
                "EWord": "We want medicine.",
                "PWord": "ਸਾਨੂੰ ਦਵਾਈ ਚਾਹੀਦੀ ਹੈ",
                "HWord": "हमें दवा चाहिए।",
                "VideoPath": "/video/We_want_medicine.mp4",
            },
            {
                "WID": "368",
                "EWord": "We want sweets.",
                "PWord": "ਸਾਨੂੰ ਮਠਿਆਈ ਚਾਹੀਦੀ ਹੈ",
                "HWord": "हमें मिठाई चाहिए।",
                "VideoPath": "/video/We_want_sweets.mp4",
            },
            {
                "WID": "369",
                "EWord": "We want water.",
                "PWord": "ਸਾਨੂੰ ਪਾਣੀ ਚਾਹੀਦਾ ਹੈ",
                "HWord": "हमें पानी चाहिए।",
                "VideoPath": "/video/We_want_water.mp4",
            },
            {
                "WID": "370",
                "EWord": "You are bad.",
                "PWord": "ਤੁਸੀਂ ਮਾੜੇ ਹੋ",
                "HWord": "आप बुरे हैं।",
                "VideoPath": "/video/You_are_bad.mp4",
            },
            {
                "WID": "371",
                "EWord": "You are good.",
                "PWord": "ਤੁਸੀਂ ਚੰਗੇ ਹੋ",
                "HWord": "आप अच्छे हैं।",
                "VideoPath": "/video/You_are_good.mp4",
            },
            {
                "WID": "372",
                "EWord": "You are intelligent.",
                "PWord": "ਤੁਸੀਂ ਬੁੱਧੀਮਾਨ ਹੋ",
                "HWord": "आप बुद्धिमान हैं।",
                "VideoPath": "/video/You_are_intelligent.mp4",
            },
            {
                "WID": "373",
                "EWord": "You are lazy.",
                "PWord": "ਤੁਸੀਂ ਆਲਸੀ ਹੋ",
                "HWord": "आप आलसी हो।",
                "VideoPath": "/video/You_are_lazy.mp4",
            },
            {
                "WID": "374",
                "EWord": "You are brave.",
                "PWord": "ਤੁਸੀਂ ਬਹਾਦਰ ਹੋ",
                "HWord": "तुम वीर हो।",
                "VideoPath": "/video/You_r_brave.mp4",
            },
            {
                "WID": "375",
                "EWord": "You are great.",
                "PWord": "ਤੁਸੀਂ ਮਹਾਨ ਹੋ",
                "HWord": "तुम महान हो।",
                "VideoPath": "/video/You_r_great.mp4",
            },
            {
                "WID": "483",
                "EWord": "Can you gave me some information.",
                "PWord": "ਕੀ ਤੁਸੀਂ ਮੈਨੂੰ ਕੁਝ ਜਾਣਕਾਰੀ ਦੇ ਸਕਦੇ ਹੋ?",
                "HWord": "क्या आप मुझे कुछ जानकारी दे सकते हो?",
                "VideoPath": "/video/Can_you_gave_me_someinfo.mp4",
            },
            {
                "WID": "484",
                "EWord": "Carry on with your work.",
                "PWord": "ਆਪਣੇ ਕੰਮ ਨੂੰ ਜਾਰੀ ਰੱਖੋ",
                "HWord": "अपना काम करते रहें।",
                "VideoPath": "/video/Carry_on_with_your_work.mp4",
            },
            {
                "WID": "485",
                "EWord": "Cars are mine.",
                "PWord": "ਕਾਰਾਂ ਮੇਰੀਆਂ ਹਨ",
                "HWord": "कारें मेरी हैं।",
                "VideoPath": "/video/cars_are_mine.mp4",
            },
            {
                "WID": "486",
                "EWord": "Come to my house.",
                "PWord": "ਮੇਰੇ ਘਰ ਆਓ ",
                "HWord": " मेरे घर आओ ",
                "VideoPath": "/video/Come_to_my_house.mp4",
            },
            {
                "WID": "487",
                "EWord": "Father had gone to bank.",
                "PWord": "ਪਿਤਾ ਬੈਂਕ ਗਏ ਹੋਏ ਸਨ",
                "HWord": "पिता बैंक गए थे।",
                "VideoPath": "/video/Father_had_gone_to _bank.mp4",
            },
            {
                "WID": "488",
                "EWord": "He was riding a bicycle.",
                "PWord": "ਉਹ ਸਾਈਕਲ ਚਲਾ ਰਿਹਾ ਸੀ",
                "HWord": "वह एक साइकिल चला रहा था।",
                "VideoPath": "/video/He_was_riding_a_bycycle.mp4",
            },
            {
                "WID": "489",
                "EWord": "He will come with uncle.",
                "PWord": "ਉਹ ਚਾਚੇ ਨਾਲ ਆਵੇਗਾ",
                "HWord": "वह चाचा के साथ आएगा।",
                "VideoPath": "/video/He_will_come.mp4",
            },
            {
                "WID": "490",
                "EWord": "How are you?",
                "PWord": "ਤੁਸੀ ਕਿਵੇਂ ਹੋ?",
                "HWord": "आपका क्या हाल है?",
                "VideoPath": "/video/How_are_you.mp4",
            },
            {
                "WID": "491",
                "EWord": "How can I win win the game?",
                "PWord": "ਮੈਂ ਖੇਡ ਨੂੰ ਕਿਵੇਂ ਜਿੱਤ ਸਕਦਾ ਹਾਂ?",
                "HWord": "मैं खेल कैसे जीत सकता हूं?",
                "VideoPath": "/video/How_can_i_win_the_game.mp4",
            },
            {
                "WID": "492",
                "EWord": "How is your wife?",
                "PWord": "ਤੁਹਾਡੀ ਪਤਨੀ ਕਿਵੇ ਹੈ?",
                "HWord": "आपकी पत्नी कैसी है",
                "VideoPath": "/video/How_is_your_wife.mp4",
            },
            {
                "WID": "493",
                "EWord": "How many children do you have?",
                "PWord": "ਤੁਹਾਡੇ ਕਿੰਨੇ ਬੱਚੇ ਹਨ?",
                "HWord": "आप के कितने बच्चे हैं?",
                "VideoPath": "/video/How_many_children.mp4",
            },
            {
                "WID": "494",
                "EWord": "How old are you?",
                "PWord": "ਤੁਹਾਡੀ ਉਮਰ ਕੀ ਹੈ?",
                "HWord": "आप की उम्र क्या है?",
                "VideoPath": "/video/How_old_are_you.mp4",
            },
            {
                "WID": "495",
                "EWord": "If I am in London",
                "PWord": "ਜੇ ਮੈਂ ਲੰਡਨ ਵਿਚ ਹਾਂ",
                "HWord": "अगर मैं लंदन में हूं",
                "VideoPath": "/video/If_i_an_inLondon.mp4",
            },
            {
                "WID": "496",
                "EWord": "Is my passport ready?",
                "PWord": "ਕੀ ਮੇਰਾ ਪਾਸਪੋਰਟ ਤਿਆਰ ਹੈ?",
                "HWord": "क्या मेरा पासपोर्ट तैयार है?",
                "VideoPath": "/video/Is_my_passport_ready.mp4",
            },
            {
                "WID": "497",
                "EWord": "It is hotter now.",
                "PWord": "ਇਹ ਹੁਣ ਗਰਮ ਹੈ",
                "HWord": "यह अब गर्म है।",
                "VideoPath": "/video/It;s_hotter_now.mp4",
            },
            {
                "WID": "498",
                "EWord": "I am proud of myself.",
                "PWord": "ਮੈਨੂੰ ਆਪਣੇ ਤੇ ਮਾਣ ਹੈ",
                "HWord": "मुझे अपने आप पर गर्व है।",
                "VideoPath": "/video/I_am_proud_of_myself.mp4",
            },
            {
                "WID": "499",
                "EWord": "I came to Australia to study English.",
                "PWord": "ਮੈਂ ਆਸਟ੍ਰੇਲੀਆ ਤੋਂ ਅੰਗਰੇਜ਼ੀ ਪੜ੍ਹਨ ਆਇਆ ਸੀ।",
                "HWord": "मैं अंग्रेजी पढ़ने के लिए ऑस्ट्रेलिया आया था।",
                "VideoPath": "/video/I_came_to_Aus.mp4",
            },
            {
                "WID": "500",
                "EWord": "I closed the door very quickly.",
                "PWord": "ਮੈਂ ਬਹੁਤ ਤੇਜ਼ੀ ਨਾਲ ਦਰਵਾਜਾ ਬੰਦ ਕਰ ਦਿੱਤਾ",
                "HWord": "मैंने बहुत जल्दी दरवाजा बंद कर दिया।",
                "VideoPath": "/video/I_closed_the_door.mp4",
            },
            {
                "WID": "501",
                "EWord": "I corrected myself.",
                "PWord": "ਮੈਂ ਆਪਣੇ ਆਪ ਨੂੰ ਠੀਕ ਕੀਤਾ",
                "HWord": "मैंने खुद को ठीक किया।",
                "VideoPath": "/video/I_corrected_myself.mp4",
            },
            {
                "WID": "502",
                "EWord": "I did not meet anybody.",
                "PWord": "ਮੈਂ ਕਿਸੇ ਨੂੰ ਨਹੀਂ ਮਿਲਿਆ",
                "HWord": "मैं किसी से नहीं मिला।",
                "VideoPath": "/video/I_didn't_meet_anybody.mp4",
            },
            {
                "WID": "503",
                "EWord": "I enjoyed myself.",
                "PWord": "ਮੈਂ ਆਪਣੇ ਆਪ ਦਾ ਅਨੰਦ ਲਿਆ",
                "HWord": "मैंने अपना आनंद उठाया।",
                "VideoPath": "/video/I_enjoyed_myself.mp4",
            },
            {
                "WID": "504",
                "EWord": "I had a bowl.",
                "PWord": "ਮੇਰੇ ਕੋਲ ਇੱਕ ਕਟੋਰਾ ਸੀ।",
                "HWord": "मेरे पास एक कटोरा था।",
                "VideoPath": "/video/I_had_a_bowl.mp4",
            },
            {
                "WID": "505",
                "EWord": "I had a cat.",
                "PWord": "ਮੇਰੇ ਕੋਲ ਇੱਕ ਬਿੱਲੀ ਸੀ।",
                "HWord": "मेरे पास एक बिल्ली थी।",
                "VideoPath": "/video/I_had_a_cat.mp4",
            },
            {
                "WID": "506",
                "EWord": "He had two dogs.",
                "PWord": "ਉਸ ਕੋਲ ਦੋ ਕੁੱਤੇ ਸਨ",
                "HWord": "उसके पास दो कुत्ते थे।",
                "VideoPath": "/video/I_had_two_dogs.mp4",
            },
            {
                "WID": "507",
                "EWord": "I have been here for 3 months.",
                "PWord": "ਮੈਂ ਇੱਥੇ 3 ਮਹੀਨੇ ਰਿਹਾ ਹਾਂ",
                "HWord": "मैं यहां 3 महीने से हूं।",
                "VideoPath": "/video/I_have_been_here_for_last_3month.mp4",
            },
            {
                "WID": "508",
                "EWord": "I promise I will call you next week.",
                "PWord": "ਮੈਂ ਵਾਅਦਾ ਕਰਦਾ ਹਾਂ ਕਿ ਮੈਂ ਤੁਹਾਨੂੰ ਅਗਲੇ ਹਫਤੇ ਬੁਲਾਵਾਂਗਾ",
                "HWord": "मैं वादा करता हूं कि मैं आपको अगले हफ्ते बुलाऊंगा।",
                "VideoPath": "/video/I_promise_i'll_call.mp4",
            },
            {
                "WID": "509",
                "EWord": "I was angry at myself.",
                "PWord": "ਮੈਨੂੰ ਆਪਣੇ ਤੇ ਗੁੱਸਾ ਸੀ।",
                "HWord": "मुझे अपने आप पर गुस्सा आ रहा था।",
                "VideoPath": "/video/I_was_angry_at_myself.mp4",
            },
            {
                "WID": "510",
                "EWord": "I was watching TV.",
                "PWord": "ਮੈਂ ਟੀ.ਵੀ. ਵੇਖ ਰਿਹਾ ਸੀ",
                "HWord": "मैं टी.वी. देख रहा था।",
                "VideoPath": "/video/I_was_watching_tv.mp4",
            },
            {
                "WID": "511",
                "EWord": "I will come to you.",
                "PWord": "ਮੈਂ ਤੁਹਾਡੇ ਕੋਲ ਆਵਾਂਗਾ",
                "HWord": "मैं आपके पास आऊंगा।",
                "VideoPath": "/video/I_will_come_to_you.mp4",
            },
            {
                "WID": "512",
                "EWord": "I will drive myself.",
                "PWord": "ਮੈਂ ਆਪਣੇ ਆਪ ਚਲਾਵਾਂਗਾ",
                "HWord": "मैं खुद चलाऊंगा।",
                "VideoPath": "/video/I_will_drive _myself.mp4",
            },
            {
                "WID": "513",
                "EWord": "Johnny and I live here.",
                "PWord": "ਮੈਂ ਅਤੇ ਜੌਨੀ ਇੱਥੇ ਰਹਿੰਦੇ ਹਾਂ",
                "HWord": "जॉनी और मैं यहां रहते हैं।",
                "VideoPath": "/video/Johnny_and_I_live.mp4",
            },
            {
                "WID": "514",
                "EWord": "Life is hard.",
                "PWord": "ਜ਼ਿੰਦਗੀ ਔਖੀ ਹੈ",
                "HWord": "जीवन कठिन है।",
                "VideoPath": "/video/Life_is_hard.mp4",
            },
            {
                "WID": "515",
                "EWord": "Mother was filling a jug.",
                "PWord": "ਮਾਂ ਜੱਗ ਭਰ ਰਹੀ ਸੀ",
                "HWord": "माँ एक जग भर रही थी।",
                "VideoPath": "/video/Mother_was_filling_a_jug.mp4",
            },
            {
                "WID": "516",
                "EWord": "My boyfriend has got a new job.",
                "PWord": "ਮੇਰੇ ਬੁਆਏਫ੍ਰੈਂਡ ਨੂੰ ਨਵੀਂ ਨੌਕਰੀ ਮਿਲ ਗਈ ਹੈ",
                "HWord": "मेरे बॉयफ्रेंड को नई नौकरी मिल गई है।",
                "VideoPath": "/video/My_boyfriend_has_got_a_newjob.mp4",
            },
            {
                "WID": "517",
                "EWord": "My brother is 10 years old.",
                "PWord": "ਮੇਰਾ ਭਰਾ 10 ਸਾਲ ਦਾ ਹੈ",
                "HWord": "मेरा भाई 10 साल का है।",
                "VideoPath": "/video/My_brother_is_10.mp4",
            },
            {
                "WID": "518",
                "EWord": "My elbow was injured.",
                "PWord": "ਮੇਰੀ ਕੂਹਣੀ ਜ਼ਖਮੀ ਹੋ ਗਈ ਸੀ",
                "HWord": "मेरी कोहनी जख्मी हो गई थी।",
                "VideoPath": "/video/My_elbow_was_injured.mp4",
            },
            {
                "WID": "519",
                "EWord": "My father is tall.",
                "PWord": "ਮੇਰਾ ਪਿਤਾ ਲੰਮੇ ਹਨ ",
                "HWord": "मेरे पिता लम्बे है।",
                "VideoPath": "/video/My_father_is_tall.mp4",
            },
            {
                "WID": "520",
                "EWord": "My flight departs at 5:00 am.",
                "PWord": "ਮੇਰੀ ਉਡਾਣ ਸਵੇਰੇ 5:00 ਵਜੇ ਰਵਾਨਾ ਹੋਵੇਗੀ।",
                "HWord": "मेरी उड़ान सुबह 5:00 बजे रवाना होती है।",
                "VideoPath": "/video/My_flight_departs_at_5am.mp4",
            },
            {
                "WID": "521",
                "EWord": "My friend was angry with me.",
                "PWord": "ਮੇਰਾ ਦੋਸਤ ਮੇਰੇ ਨਾਲ ਨਾਰਾਜ਼ ਸੀ।",
                "HWord": "मेरा दोस्त मुझसे नाराज था।",
                "VideoPath": "/video/My_friend_was_angry_with_me.mp4",
            },
            {
                "WID": "522",
                "EWord": "My husband is an engineer.",
                "PWord": "ਮੇਰਾ ਪਤੀ ਇੰਜੀਨੀਅਰ ਹੈ।",
                "HWord": "मेरे पति इंजीनियर हैं।",
                "VideoPath": "/video/My_husband_is_an_engineer.mp4",
            },
            {
                "WID": "523",
                "EWord": "My mother had a cake.",
                "PWord": "ਮੇਰੀ ਮਾਂ ਕੋਲ ਇੱਕ ਕੇਕ ਸੀ",
                "HWord": "मेरी माँ के पास एक केक था।",
                "VideoPath": "/video/My_mother_had_a_cake.mp4",
            },
            {
                "WID": "524",
                "EWord": "My is wanted me to be a doctor.",
                "PWord": "ਮੇਰੀ ਇੱਛਾ ਹੈ ਕਿ ਮੈਂ ਇੱਕ ਡਾਕਟਰ ਬਣਾਂ",
                "HWord": "मेरी इच्छा थी कि मैं डॉक्टर बनूं।",
                "VideoPath": "/video/My_mother_wanted_metobe.mp4",
            },
            {
                "WID": "525",
                "EWord": "My sister will play football.",
                "PWord": "ਮੇਰੀ ਭੈਣ ਫੁਟਬਾਲ ਖੇਡੇਗੀ",
                "HWord": "मेरी बहन फुटबॉल खेलेगी।",
                "VideoPath": "/video/My_sister_will_play_football.mp4",
            },
            {
                "WID": "526",
                "EWord": "Please explain to me how to improve my english.",
                "PWord": "ਕਿਰਪਾ ਕਰਕੇ ਮੈਨੂੰ ਸਮਝਾਓ ਕਿ ਮੇਰੀ ਅੰਗਰੇਜ਼ੀ ਕਿਵੇਂ ਸੁਧਾਰੀਏ",
                "HWord": "कृपया मुझे समझाएं कि मैं अपनी अंग्रेजी कैसे सुधारूं।",
                "VideoPath": "/video/Please_explainto_me.mp4",
            },
            {
                "WID": "527",
                "EWord": "She loves walking.",
                "PWord": "ਉਹ ਤੁਰਨਾ ਪਸੰਦ ਕਰਦੀ ਹੈ",
                "HWord": "उसे घूमना बहुत पसंद है।",
                "VideoPath": "/video/She_;loves _walking.mp4",
            },
            {
                "WID": "528",
                "EWord": "She does not listen to me.",
                "PWord": "ਉਹ ਮੇਰੀ ਨਹੀਂ ਸੁਣਦੀ।",
                "HWord": "वह मेरी बात नहीं सुनती।",
                "VideoPath": "/video/She_doesn't_listen_to_me.mp4",
            },
            {
                "WID": "529",
                "EWord": "She had a bangle.",
                "PWord": "ਉਸ ਕੋਲ਼ ਚੂੜੀ ਸੀ ",
                "HWord": "उसके पास चूड़ी थी।",
                "VideoPath": "/video/She_had_a_bangle.mp4",
            },
            {
                "WID": "530",
                "EWord": "She is successful.",
                "PWord": "ਉਹ ਸਫਲ ਹੈ",
                "HWord": "वह सफल है।",
                "VideoPath": "/video/She_is_successfull.mp4",
            },
            {
                "WID": "531",
                "EWord": "She married to a dentist.",
                "PWord": "ਉਸ ਨੇ ਇੱਕ ਦੰਦਾਂ ਦੇ ਡਾਕਟਰ ਨਾਲ ਵਿਆਹ ਕੀਤਾ",
                "HWord": "उसने एक दंत चिकित्सक से शादी की।",
                "VideoPath": "/video/She_married_to_a_dentiest.mp4",
            },
            {
                "WID": "532",
                "EWord": "She told me that she liked you.",
                "PWord": "ਉਸਨੇ ਮੈਨੂੰ ਦੱਸਿਆ ਕਿ ਉਹ ਤੁਹਾਨੂੰ ਪਸੰਦ ਕਰਦੀ ਹੈ",
                "HWord": "उसने मुझसे कहा कि वह तुम्हें पसंद करती है।",
                "VideoPath": "/video/She_told_me_that_see.mp4",
            },
            {
                "WID": "533",
                "EWord": "She was singing.",
                "PWord": "ਉਹ ਗਾ ਰਹੀ ਸੀ।",
                "HWord": "वह गा रही थी।",
                "VideoPath": "/video/She_was_singing.mp4",
            },
            {
                "WID": "534",
                "EWord": "That is a baby girl.",
                "PWord": "ਉਹ ਇਕ ਬੱਚੀ ਹੈ",
                "HWord": "वह एक बच्ची है।",
                "VideoPath": "/video/That_ia_a_baby_girl.mp4",
            },
            {
                "WID": "535",
                "EWord": "That is a ball.",
                "PWord": "ਉਹ ਇਕ ਗੇਂਦ ਹੈ",
                "HWord": "वह एक गेंद है।",
                "VideoPath": "/video/That_is_a_ball.mp4",
            },
            {
                "WID": "536",
                "EWord": "That is a passport.",
                "PWord": "ਉਹ ਪਾਸਪੋਰਟ ਹੈ",
                "HWord": "वह पासपोर्ट है।",
                "VideoPath": "/video/That_is_a_passport.mp4",
            },
            {
                "WID": "537",
                "EWord": "That is my book.",
                "PWord": "ਇਹ ਮੇਰੀ ਕਿਤਾਬ ਹੈ",
                "HWord": "यह मेरी पुस्तक है।",
                "VideoPath": "/video/That_is_my_book.mp4",
            },
            {
                "WID": "538",
                "EWord": "That is my house.",
                "PWord": "ਉਹ ਮੇਰਾ ਘਰ ਹੈ।",
                "HWord": "यह मेरा घर हैं।",
                "VideoPath": "/video/That_is_my_house.mp4",
            },
            {
                "WID": "539",
                "EWord": "There are seven girls in the class.",
                "PWord": "ਕਲਾਸ ਵਿਚ ਸੱਤ ਲੜਕੀਆਂ ਹਨ.",
                "HWord": "कक्षा में सात लड़कियां हैं।",
                "VideoPath": "/video/There_are_7.mp4",
            },
            {
                "WID": "540",
                "EWord": "They cooked the dinner themselves.",
                "PWord": "ਉਨ੍ਹਾਂ ਨੇ ਰਾਤ ਦਾ ਖਾਣਾ ਖੁਦ ਪਕਾਇਆ।",
                "HWord": "उन्होंने रात का खाना खुद बनाया।",
                "VideoPath": "/video/They_cooked_the_dinner.mp4",
            },
            {
                "WID": "541",
                "EWord": "They had five toothbrushes.",
                "PWord": "ਉਨ੍ਹਾਂ ਕੋਲ ਪੰਜ ਟੂਥ ਬਰੱਸ਼ ਸਨ।",
                "HWord": "उनके पांच टूथब्रश थे।",
                "VideoPath": "/video/They_had_five_toothbrushes.mp4",
            },
            {
                "WID": "542",
                "EWord": "They were enjoying in party.",
                "PWord": "ਉਹ ਪਾਰਟੀ ਵਿੱਚ ਅਨੰਦ ਲੈ ਰਹੇ ਸਨ.",
                "HWord": "वह पार्टी में आनंद ले रहे थे।",
                "VideoPath": "/video/They_were_enjoying.mp4",
            },
            {
                "WID": "543",
                "EWord": "They were taking milk.",
                "PWord": "ਉਹ ਦੁੱਧ ਲੈ ਰਹੇ ਸਨ।",
                "HWord": "वह दूध ले रहे थे।",
                "VideoPath": "/video/They_were_taking_milk.mp4",
            },
            {
                "WID": "544",
                "EWord": "They will sit together.",
                "PWord": "ਉਹ ਇਕੱਠੇ ਬੈਠਣਗੇ.",
                "HWord": "वह एक साथ बैठेंगे।",
                "VideoPath": "/video/They_will_sit_together.mp4",
            },
            {
                "WID": "545",
                "EWord": "The house is not big enough.",
                "PWord": "ਘਰ ਇੰਨਾ ਵੱਡਾ ਨਹੀਂ ਹੈ.",
                "HWord": "घर ज्यादा बड़ा नहीं है।",
                "VideoPath": "/video/The_house_isn't_big.mp4",
            },
            {
                "WID": "546",
                "EWord": "The policemen are coming.",
                "PWord": "ਪੁਲਿਸ ਵਾਲੇ ਆ ਰਹੇ ਹਨ.",
                "HWord": "पुलिस वाले आ रहे हैं।",
                "VideoPath": "/video/The_Police_man_atre_coming.mp4",
            },
            {
                "WID": "547",
                "EWord": "This is a baby.",
                "PWord": "ਇਹ ਇਕ ਬੱਚਾ ਹੈ.",
                "HWord": "यह एक बच्चा है।",
                "VideoPath": "/video/This_is_a_baby.mp4",
            },
            {
                "WID": "548",
                "EWord": "This is a bed.",
                "PWord": "ਇਹ ਇਕ ਮੰਜਾ ਹੈ",
                "HWord": "यह एक बिस्तर है।",
                "VideoPath": "/video/This_is_a_bed.mp4",
            },
            {
                "WID": "549",
                "EWord": "This is a dog.",
                "PWord": "ਇਹ ਇੱਕ ਕੁੱਤਾ ਹੈ.",
                "HWord": "यह एक कुत्ता है।",
                "VideoPath": "/video/This_is_a_dog.mp4",
            },
            {
                "WID": "550",
                "EWord": "This is my car.",
                "PWord": "ਇਹ ਮੇਰੀ ਕਾਰ ਹੈ",
                "HWord": "यह मेरी कार है।",
                "VideoPath": "/video/This_is_my_car.mp4",
            },
            {
                "WID": "551",
                "EWord": "This is the passport.",
                "PWord": "ਇਹ ਪਾਸਪੋਰਟ ਹੈ",
                "HWord": "यह पासपोर्ट है।",
                "VideoPath": "/video/This_is_the_passport.mp4",
            },
            {
                "WID": "552",
                "EWord": "Tomorrow is my birthday.",
                "PWord": "ਕੱਲ ਮੇਰਾ ਜਨਮਦਿਨ ਹੈ.",
                "HWord": "कल मेरा जन्मदिन है।",
                "VideoPath": "/video/Tomorrow_is_my_birthday.mp4",
            },
            {
                "WID": "553",
                "EWord": "Uncle had come to my house.",
                "PWord": "ਚਾਚਾ ਮੇਰੇ ਘਰ ਆਏ ਹੋਏ ਸਨ।",
                "HWord": "अंकल मेरे घर आए थे।",
                "VideoPath": "/video/Uncle_had_come_to_my_house.mp4",
            },
            {
                "WID": "554",
                "EWord": "Uncle will gave me this gift.",
                "PWord": "ਚਾਚਾ ਮੈਨੂੰ ਅਜਿਹਾ ਤੋਹਫਾ ਦੇਵੇਗਾ.",
                "HWord": "अंकल मुझे ऐसा उपहार देंगे।",
                "VideoPath": "/video/Uncle_will_gave_me_gift.mp4",

            },
            {
                "WID": "555",
                "EWord": "We had a funday.",
                "PWord": "ਸਦਾ ਦਿਨ ਮਜ਼ੇਦਾਰ ਸੀ ",
                "HWord": "हमारा दिन मज़ेदार था ",
                "VideoPath": "/video/We_had_a_funday.mp4",
            },
            {
                "WID": "556",
                "EWord": "We studied for four hours.",
                "PWord": "ਅਸੀਂ ਚਾਰ ਘੰਟੇ ਪੜ੍ਹਾਈ ਕੀਤੀ.",
                "HWord": "हमने चार घंटे पढ़ाई की।",
                "VideoPath": "/video/We_studied_for_4.mp4",
            },
            {
                "WID": "557",
                "EWord": "We were going to market.",
                "PWord": "ਅਸੀਂ ਬਾਜ਼ਾਰ ਜਾ ਰਹੇ ਸੀ.",
                "HWord": "हम बाजार जा रहे थे।",
                "VideoPath": "/video/We_were_going_to_market.mp4",
            },
            {
                "WID": "558",
                "EWord": "We will go by bus.",
                "PWord": "ਅਸੀਂ ਬੱਸ ਰਾਹੀਂ ਚਲੇ ਜਾਵਾਂਗੇ.",
                "HWord": "हम बस से जाएंगे।",
                "VideoPath": "/video/We_will_go_by.mp4",
            },
            {
                "WID": "559",
                "EWord": "What are you doing?",
                "PWord": "ਤੁਸੀਂ ਕੀ ਕਰ ਰਹੇ ਹੋ?",
                "HWord": "तुम क्या कर रहे हो?",
                "VideoPath": "/video/What_are_you_doing.mp4",
            },
            {
                "WID": "560",
                "EWord": "What are you watching?",
                "PWord": "ਤੁਸੀਂ ਕੀ ਵੇਖ ਰਹੇ ਹੋ?",
                "HWord": "आप क्या देख रहे हैं?",
                "VideoPath": "/video/What_are_you_watching.mp4",
            },
            {
                "WID": "561",
                "EWord": "What are you writing?",
                "PWord": "ਤੁਸੀਂ ਕੀ ਲਿੱਖ ਰਹੇ ਹੋ?",
                "HWord": "आप क्या लिख ​​रहे हैं?",
                "VideoPath": "/video/What_are_you_writing.mp4",
            },
            {
                "WID": "562",
                "EWord": "What is your age?",
                "PWord": "ਤੁਹਾਡੀ ਉਮਰ ਕਿੰਨੀ ਹੈ?",
                "HWord": "तुम्हारी उम्र क्या हैं?",
                "VideoPath": "/video/What_is_your_age.mp4",
            },
            {
                "WID": "563",
                "EWord": "What is your father?",
                "PWord": "ਤੁਹਾਡੇ ਪਿਤਾ ਕੀ ਕਰਦੇ ਹਨ ?",
                "HWord": "आपके पिता क्या करते हैं?",
                "VideoPath": "/video/What_is_your_father.mp4",
            },
            {
                "WID": "564",
                "EWord": "What is your name?",
                "PWord": "ਤੁਹਾਡਾ ਨਾਮ ਕੀ ਹੈ?",
                "HWord": "तुम्हारा नाम क्या हे?",
                "VideoPath": "/video/What_is_your_name.mp4",
            },
            {
                "WID": "565",
                "EWord": "When are you getting married?",
                "PWord": "ਤੁਹਾਡਾ ਵਿਆਹ ਕਦੋਂ ਹੋ ਰਿਹਾ ਹੈ?",
                "HWord": "आप कब शादी कर रहे हैं?",
                "VideoPath": "/video/When_are_you_getting_married.mp4",
            },
            {
                "WID": "566",
                "EWord": "When did you go there?",
                "PWord": "ਤੁਸੀਂ ਉਥੇ ਕਦੋਂ ਗਏ ਸੀ?",
                "HWord": "आप वहाँ कब गए थे ?",
                "VideoPath": "/video/When_did_you_go_there.mp4",
            },
            {
                "WID": "567",
                "EWord": "When did you play cricket?",
                "PWord": "ਤੁਸੀਂ ਕ੍ਰਿਕੇਟ ਕਦੋਂ ਖੇਡਿਆ?",
                "HWord": "आपने क्रिकेट कब खेला?",
                "VideoPath": "/video/When_did_you_play_cricket.mp4",
            },
            {
                "WID": "568",
                "EWord": "When do you play cricket?",
                "PWord": "ਤੁਸੀਂ ਕ੍ਰਿਕੇਟ ਕਦੋਂ ਖੇਡਦੇ ਹੋ?",
                "HWord": "आप क्रिकेट कब खेलते हैं?",
                "VideoPath": "/video/When_do_you_play_cricket.mp4",
            },
            {
                "WID": "569",
                "EWord": "When do you sing your prayer?",
                "PWord": "ਤੁਸੀਂ ਆਪਣੀ ਅਰਦਾਸ ਕਦੋਂ ਗਾਉਂਦੇ ਹੋ?",
                "HWord": "आप अपनी प्रार्थना कब गाते हैं?",
                "VideoPath": "/video/When_do_you_sing_your_prayer.mp4",
            },
            {
                "WID": "570",
                "EWord": "When will you call him?",
                "PWord": "ਤੁਸੀਂ ਉਸਨੂੰ ਕਦੋਂ ਬੁਲਾਓਗੇ?",
                "HWord": "तुम उसे कब बुलाओगे?",
                "VideoPath": "/video/When_will_you_call_him.mp4",
            },
            {
                "WID": "571",
                "EWord": "When will you go to picnic?",
                "PWord": "ਤੁਸੀਂ ਪਿਕਨਿਕ ਕਦੋਂ ਜਾਵੋਗੇ?",
                "HWord": "आप पिकनिक कब जाएंगे?",
                "VideoPath": "/video/When_will_you_go_to_picnic.mp4",
            },
            {
                "WID": "572",
                "EWord": "What are you eating?",
                "PWord": "ਤੁਸੀਂ ਕੀ ਖਾ ਰਹੇ ਹੋ?",
                "HWord": "तुम क्या खा रहे हो?",
                "VideoPath": "/video/Where_are_you_eating.mp4",
            },
            {
                "WID": "573",
                "EWord": "Where is post office?",
                "PWord": "ਡਾਕਘਰ ਕਿੱਥੇ ਹੈ?",
                "HWord": "डाकघर कहां है?",
                "VideoPath": "/video/Where_is_the_postoffice.mp4",
            },
            {
                "WID": "574",
                "EWord": "Which is your favourite book?",
                "PWord": "ਤੁਹਾਡੀ ਮਨਪਸੰਦ ਕਿਤਾਬ ਕਿਹੜੀ ਹੈ?",
                "HWord": "आपकी पसंदीदा पुस्तक कौन सी है?",
                "VideoPath": "/video/Which_is_your_favourite_book.mp4",
            },
            {
                "WID": "575",
                "EWord": "Which shirt will you wear tomorrow?",
                "PWord": "ਤੁਸੀਂ ਕੱਲ ਕਿਹੜੀ ਕਮੀਜ਼ ਪਹਿਨੋਂਗੇ?",
                "HWord": "कल कौन सी शर्ट पहनोगे?",
                "VideoPath": "/video/Which_shirt_will_you_wear_tomorrow.mp4",
            },
            {
                "WID": "576",
                "EWord": "Which song can you sing?",
                "PWord": "ਤੁਸੀਂ ਕਿਹੜਾ ਗੀਤ ਗਾ ਸਕਦੇ ਹੋ?",
                "HWord": "आप कौन सा गाना गा सकते हैं?",
                "VideoPath": "/video/Which_song_can_you_sing.mp4",
            },
            {
                "WID": "577",
                "EWord": "Whose bicycle is this?",
                "PWord": "ਇਹ ਕਿਸ ਦਾ ਸਾਈਕਲ ਹੈ?",
                "HWord": "यह किसकी साइकिल है?",
                "VideoPath": "/video/Whose_bicycle_is_this.mp4",
            },
            {
                "WID": "578",
                "EWord": "Whose purse have you found?",
                "PWord": "ਤੁਹਾਨੂੰ ਕਿੱਸਦਾ ਪਰਸ ਮਿੱਲਿਆ ਹੈ?",
                "HWord": "आपको किसका पर्स मिला है?",
                "VideoPath": "/video/WHose_purse_have_you_found.mp4",
            },
            {
                "WID": "579",
                "EWord": "Whose voice was that?",
                "PWord": "ਉਹ ਕਿਸਦੀ ਆਵਾਜ਼ ਸੀ?",
                "HWord": "वह किसकी आवाज थी?",
                "VideoPath": "/video/Whose_voice_was_that.mp4",
            },
            {
                "WID": "580",
                "EWord": "Why did you sell your car?",
                "PWord": "ਤੁਸੀਂ ਆਪਣੀ ਕਾਰ ਕਿਉਂ ਵੇਚੀ?",
                "HWord": "आपने अपनी कार क्यों बेची?",
                "VideoPath": "/video/Why_did_you_sell_your_car.mp4",
            },
            {
                "WID": "581",
                "EWord": "Why don't you help me?",
                "PWord": "ਤੁਸੀਂ ਮੇਰੀ ਮਦਦ ਕਿਉਂ ਨਹੀਂ ਕਰਦੇ?",
                "HWord": "आप मेरी मदद क्यों नहीं करते?",
                "VideoPath": "/video/Why_don't_you_help_me.mp4",
            },
            {
                "WID": "582",
                "EWord": "Why has he insulted me?",
                "PWord": "ਉਸਨੇ ਮੇਰਾ ਅਪਮਾਨ ਕਿਉਂ ਕੀਤਾ ਹੈ?",
                "HWord": "उसने मेरा अपमान क्यों किया है?",
                "VideoPath": "/video/Why_has_he_insulted_me.mp4",
            },
            {
                "WID": "583",
                "EWord": "Why have you come late?",
                "PWord": "ਤੁਸੀਂ ਦੇਰ ਨਾਲ ਕਿਉਂ ਆਏ ਹੋ?",
                "HWord": "तुम देर से क्यों आए हो?",
                "VideoPath": "/video/Why_have_you_come_late.mp4",
            },
            {
                "WID": "584",
                "EWord": "Would you like a glass of wine?",
                "PWord": "ਕੀ ਤੁਸੀਂ ਇੱਕ ਗਲਾਸ ਸ਼ਰਾਬ ਚਾਹੁੰਦੇ ਹੋ?",
                "HWord": "क्या आप एक गिलास शराब लेना पसंद करेंगे?",
                "VideoPath": "/video/Would_you_like_a_glass_of_wine.mp4",
            },
            {
                "WID": "585",
                "EWord": "Would you like to dance with me?",
                "PWord": "ਕੀ ਤੁਸੀਂ ਮੇਰੇ ਨਾਲ ਨੱਚਣਾ ਚਾਹੋਗੇ?",
                "HWord": "क्या आप मेरे साथ नाचना पसंद करेंगे?",
                "VideoPath": "/video/Would_you_like_to_sance.mp4",
            },
            {
                "WID": "586",
                "EWord": "Your are very nice like your mother.",
                "PWord": "ਤੁਸੀਂ ਆਪਣੀ ਮਾਂ ਵਾਂਗ ਬਹੁਤ ਚੰਗੇ ਹੋ.",
                "HWord": "आप अपनी माँ की तरह बहुत अच्छी हैं।",
                "VideoPath": "/video/You_are_very_nice.mp4",
            },
            {
                "WID": "587",
                "EWord": "You Cannot buy all what You like!",
                "PWord": "ਤੁਸੀਂ ਉਹ ਸਭ ਨਹੀਂ ਖਰੀਦ ਸਕਦੇ ਜੋ ਤੁਸੀਂ ਚਾਹੁੰਦੇ ਹੋ!",
                "HWord": "आप वह सब नहीं खरीद सकते जो आपको पसंद है!",
                "VideoPath": "/video/You_can't_buy_all.mp4",
            },
            {
                "WID": "588",
                "EWord": "You should not smoke.",
                "PWord": "ਤੁਹਾਨੂੰ ਸਿਗਰਟ ਨਹੀਂ ਪੀਣੀ ਚਾਹੀਦੀ.",
                "HWord": "तुम्हे धूम्रपान नहीं करना चाहिए।",
                "VideoPath": "/video/You_should_not_smoke.mp4",
            },
            {
                "WID": "589",
                "EWord": "You speak english well.",
                "PWord": "ਤੁਸੀਂ ਅੰਗਰੇਜ਼ੀ ਚੰਗੀ ਤਰ੍ਹਾਂ ਬੋਲਦੇ ਹੋ।",
                "HWord": "आप अंग्रेजी बहुत अच्छी बोलते हैं।",
                "VideoPath": "/video/You_speak_english_well.mp4",
            },
            {
                "WID": "590",
                "EWord": "Book is lying on the table.",
                "PWord": "ਕਿਤਾਬ ਮੇਜ਼ ਉੱਤੇ ਪਈ ਹੈ.",
                "HWord": "पुस्तक मेज पर पड़ी है।",
                "VideoPath": "/video/Book_is_lying_on_table.mp4",
            },
            {
                "WID": "591",
                "EWord": "Carry on with your work.",
                "PWord": "ਆਪਣੇ ਕੰਮ ਨੂੰ ਜਾਰੀ ਰੱਖੋ.",
                "HWord": "अपना काम करते रहें।",
                "VideoPath": "/video/Carry_on_with_your_work.mp4",
            },
            {
                "WID": "592",
                "EWord": "He has not recovered from illness.",
                "PWord": "ਉਹ ਬਿਮਾਰੀ ਤੋਂ ਠੀਕ ਨਹੀਂ ਹੋਇਆ ਹੈ।",
                "HWord": "वह बीमारी से उबर नहीं पाया है।",
                "VideoPath": "/video/he_has_not_recovered_from.mp4",
            },
            {
                "WID": "593",
                "EWord": "He was addicted to drugs.",
                "PWord": "ਉਹ ਨਸ਼ਿਆਂ ਦਾ ਆਦੀ ਸੀ।",
                "HWord": "वह नशे का आदी था।",
                "VideoPath": "/video/He_was_addicted_to_drugs.mp4",
            },
            {
                "WID": "594",
                "EWord": "Many students were absent from school.",
                "PWord": "ਬਹੁਤ ਸਾਰੇ ਵਿਦਿਆਰਥੀ ਸਕੂਲ ਤੋਂ ਗੈਰਹਾਜ਼ਰ ਸਨ।",
                "HWord": "कई छात्र स्कूल से अनुपस्थित थे।",
                "VideoPath": "/video/Many_Student_were_absent.mp4",
            },
            {
                "WID": "595",
                "EWord": "She is coming from market.",
                "PWord": "ਉਹ ਬਾਜ਼ਾਰ ਤੋਂ ਆ ਰਹੀ ਹੈ.",
                "HWord": "वह बाजार से आ रही है।",
                "VideoPath": "/video/She_is_coming_from_market.mp4",
            },
            {
                "WID": "596",
                "EWord": "Sister is free from danger.",
                "PWord": "ਭੈਣ ਖ਼ਤਰੇ ਤੋਂ ਮੁਕਤ ਹੈ।",
                "HWord": "बहन खतरे से मुक्त है।",
                "VideoPath": "/video/Sister_is_free_from_danger.mp4",
            },
            {
                "WID": "597",
                "EWord": "Teacher is going to school.",
                "PWord": "ਅਧਿਆਪਕ ਸਕੂਲ ਜਾ ਰਿਹਾ ਹੈ.",
                "HWord": "शिक्षक स्कूल जा रहा है।",
                "VideoPath": "/video/Teacher_is_going_to_school.mp4",
            },
            {
                "WID": "598",
                "EWord": "Where are you going?",
                "PWord": "ਤੂੰ ਕਿੱਥੇ ਜਾ ਰਿਹਾ ਹੈ?",
                "HWord": "तुम कहाँ जा रहे हो?",
                "VideoPath": "/video/Where_are_you_going.mp4",
            },
            {
                "WID": "599",
                "EWord": "Where is your mother?",
                "PWord": "ਤੁਹਾਡੀ ਮਾਂ ਕਿੱਥੇ ਹੈ?",
                "HWord": "तुम्हारी माँ कहाँ हैं?",
                "VideoPath": "/video/Where_is_your_mother.mp4",
            },
            {
                "WID": "600",
                "EWord": "Where is your phone?",
                "PWord": "ਤੁਹਾਡਾ ਫੋਨ ਕਿੱਥੇ ਹੈ?",
                "HWord": "आपका फोन कहां है?",
                "VideoPath": "/video/Where_is_your_phone.mp4",
            },
            {
                "WID": "601",
                "EWord": "Where is your residence?",
                "PWord": "ਤੁਹਾਡੀ ਨਿਵਾਸ ਕਿੱਥੇ ਹੈ?",
                "HWord": "तुम्हारा निवास कहाँ है?",
                "VideoPath": "/video/Where_is_your_residence.mp4",
            },
            {
                "WID": "602",
                "EWord": "Who can finish this work?",
                "PWord": "ਇਹ ਕੰਮ ਕੌਣ ਪੂਰਾ ਕਰ ਸਕਦਾ ਹੈ?",
                "HWord": "इस काम को कौन पूरा कर सकता है?",
                "VideoPath": "/video/Who_can_finish_this_work.mp4",
            },
            {
                "WID": "603",
                "EWord": "Who is he?",
                "PWord": "ਉਹ ਕੌਣ ਹੈ?",
                "HWord": "वह कौन है?",
                "VideoPath": "/video/Who_is_he.mp4",
            },
            {
                "WID": "604",
                "EWord": "Who will go to playground?",
                "PWord": "ਕੌਣ ਖੇਡ ਦੇ ਮੈਦਾਨ ਵਿਚ ਜਾਵੇਗਾ?",
                "HWord": "खेल के मैदान में कौन जाएगा?",
                "VideoPath": "/video/Who_will_go_to__plauground.mp4",
            },
            {
                "WID": "605",
                "EWord": "Whom can I trust?",
                "PWord": "ਮੈਂ ਕਿਸ ਤੇ ਭਰੋਸਾ ਕਰ ਸਕਦਾ ਹਾਂ?",
                "HWord": "मैं किस पर भरोसा कर सकता हूं?",
                "VideoPath": "/video/Whom_can_i_trust.mp4",
            },
            {
                "WID": "606",
                "EWord": "Whom did he blame?",
                "PWord": "ਉਸਨੇ ਕਿਸ ਨੂੰ ਦੋਸ਼ੀ ਠਹਿਰਾਇਆ?",
                "HWord": "उसने किसे दोषी ठहराया?",
                "VideoPath": "/video/Whom_did_he_blame.mp4",
            },
            {
                "WID": "607",
                "EWord": "Whom do you think guilty?",
                "PWord": "ਤੁਸੀਂ ਕਿਸਨੂੰ ਦੋਸ਼ੀ ਮੰਨਦੇ ਹੋ?",
                "HWord": "आप किसे दोषी मानते हैं?",
                "VideoPath": "/video/Whom_do_you_think_guitly.mp4",
            },
            {
                "WID": "608",
                "EWord": "Whom will you meet tomorrow?",
                "PWord": "ਕੱਲ ਤੁਸੀਂ ਕਿਸ ਨੂੰ ਮਿਲੋਗੇ?",
                "HWord": "कल आप किससे मिलेंगे?",
                "VideoPath": "/video/Whom_will_you_meet_tomorrow.mp4",
            },
            {
                "WID": "609",
                "EWord": "Whose book did you read?",
                "PWord": "ਤੁਸੀਂ ਕਿਸ ਦੀ ਕਿਤਾਬ ਪੜ੍ਹੀ?",
                "HWord": "आपने किसकी किताब पढ़ी?",
                "VideoPath": "/video/Whose_book_did_you_read.mp4",
            }
        ]
} 